import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { materialsCategoriesState } from "@/store/material-categories";
import { useGomakeAxios, useGomakeRouter, useSnackBar } from "@/hooks";
import {
  getAndSetChatBotProductId,
  getAndSetProductById,
} from "@/services/hooks";
import {
  currentCalculationConnectionId,
  foldOptionsState,
  isActionEmployeeUpdatedState,
  isChangePriceForEditState,
  isLoadgingState,
  itemParmetersValuesState,
  listEmployeesAtom,
  loadingWithShadowState,
  productItemValueByEditState,
  productTypesNumberState,
  selectedValueConfigState,
  selectParameterButtonState,
  subProductsCopyParametersState,
  subProductsParametersState,
} from "@/store";
import cloneDeep from "lodash/cloneDeep";
import lodashClonedeep from "lodash.clonedeep";
import { EWidgetProductType } from "@/pages-components/products/digital-offset-price/enums";
import { compareStrings, getParameterByParameterCode } from "@/utils/constants";
import {
  DuplicateType,
  EButtonTypes,
  EParameterTypes,
  GraphicsTypesParam,
  SampleTypeParm,
} from "@/enums";
import { QuantityParameter } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/quantity-parameter/quantity-parameter";
import { InputNumberParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/input-number-parameter";
import { DropDownListParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/drop-down-list-parameter";
import { SelectChildParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/select-child-parameter";
import { SWITCHParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/switch-parameter";
import { ButtonParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/button-parameter";

import {
  additionalProductItemsState,
  calculationExceptionsLogsState,
  calculationProgressState,
  calculationResultState,
  currentProductItemValueDraftId,
  currentProductItemValuePriceState,
  currentProductItemValueState,
  currentProductItemValueTotalWorkFlowsState,
  jobActionsState,
  jobDetailsState,
  lastProductItemValuePriceState,
  outsourceSuppliersState,
  productUrgentWorkState,
  selectedWorkFlowState,
  workFlowsState,
} from "@/widgets/product-pricing-widget/state";
import { getOutsourcingSuppliersListApi } from "@/services/api-service/suppliers/suppliers-endpoints";
import { useCalculationsWorkFlowsSignalR } from "../signalr/use-calculations-workflows-signalr";
import { v4 as uuidv4 } from "uuid";
import {
  addItemApi,
  getProductByItemIdApi,
  updateDocumentItemApi,
} from "@/services/api-service/generic-doc/documents-api";
import {
  openQuantityComponentModalState,
  productQuantityTypesValuesState,
  tempProductQuantityTypesValuesState,
} from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/quantity-parameter/quantity-types/state";
import {
  checkIfDieCutOrDieKissCutExists,
  findParameterByCode,
  hasValues,
} from "@/utils/helpers";
import React from "react";
import { getCurrenciesSymbols } from "@/services/api-service/general/enums";
import { currenciesSymbols } from "@/widgets/materials-widget/state";
import { exampleTypeState } from "@/store/example-type";
import { billingMethodState } from "@/store/billing-method";
import { useDebouncedCallback } from "use-debounce";
import { getDeviceSizeMockApi } from "@/services/api-service/materials/materials-endpoints";
import { AdvertisingProductCategoryParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/advertising-product-category";
import { AdvertisingProductNameParameterWidget } from "@/pages-components/products/digital-offset-price/widgets/render-parameter-widgets/advertising-product-name";
import { getAllFoldOptions } from "@/services/api-service/parameters/parameters-outputs";
import { useMaterials } from "../use-materials";
import { ESourceView } from "@/enums/products";

const useDigitalOffsetPrice = ({ clasess, widgetType }) => {
  const [, setOpenQuantityComponentModal] = useRecoilState<boolean>(
    openQuantityComponentModalState
  );
  const { navigate } = useGomakeRouter();
  const { callApi } = useGomakeAxios();
  const { t } = useTranslation();
  const router = useRouter();
  const { alertFaultAdded, alertFaultUpdate, alertFault } = useSnackBar();
  const [isChargeForNewDie, setIsChargeForNewDie] = useState(false);
  const { allMaterials, getAllMaterial } = useMaterials();
  const [selectedValueConfig, setSelectedValueConfig] = useRecoilState(
    selectedValueConfigState
  );
  const productTypesNumber = useRecoilValue<number>(productTypesNumberState);
  const setFoldOptions = useSetRecoilState(foldOptionsState);

  const [workTypes, setWorkTypes] = useState<any>([]);
  const [missionsListForEdit, setMissionsListForEdit] = useState<any>([]);
  const [sampleType, setSampleType] = useState();
  const [isRequiredParameters, setIsRequiredParameters] = useState<any>([]);
  const [activeSectionRequiredParameters, setActiveSectionRequiredParameters] =
    useState([]);
  const [documentItemByEdit, setdocumentItemByEdit] = useState<any>({});
  const [productItemValueByEdit, setProductItemValueByEdit] =
    useRecoilState<any>(productItemValueByEditState);
  const [GalleryModalOpen, setGalleryModalOpen] = useState(false);
  const [multiParameterModal, setMultiParameterModal] = useState(false);
  const [makeShapeOpen, setMakeShapeOpen] = useState(false);
  const [urgentOrder, setUrgentOrder] = useRecoilState(productUrgentWorkState);
  const [printingNotes, setPrintingNotes] = useState("");
  const [graphicNotes, setGraphicNotes] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [productTemplate, setProductTemplate] = useState<any>([]);
  const [updatedProductTemplate, setupdatedProductTemplate] = useState<any>();
  const [defaultProductTemplate, setDefaultProductTemplate] = useState<any>([]);
  const [subProducts, setSubProducts] = useRecoilState<any>(
    subProductsParametersState
  );
  const [subProductsColorsModal, setSubProductsColorsModal] =
    useRecoilState<any>(subProductsCopyParametersState);
  const [isSetTemplete, setIsSetTemplete] = useState<boolean>(false);
  const setSubProductsCopy = useSetRecoilState<any>(
    subProductsCopyParametersState
  );
  // const itemParmetersValues = useRecoilValue(itemParmetersValuesState);
  const [currentProductItemValue, setCurrentProductItemValue] =
    useRecoilState<any>(currentProductItemValueState);
  const [productItemValueDraftId, setCurrentProductItemValueDraftId] =
    useRecoilState<string>(currentProductItemValueDraftId);
  const setAdditionalProductItems = useSetRecoilState<any>(
    additionalProductItemsState
  );
  const [isFlowChanged, stIsFlowChanged] = useState(false);
  const [
    currentProductItemValueTotalPrice,
    setCurrentProductItemValueTotalPrice,
  ] = useRecoilState<number>(currentProductItemValuePriceState);
  const [lastProductItemValueTotalPrice, setLastProductItemValueTotalPrice] =
    useRecoilState<number>(lastProductItemValuePriceState);
  const setCurrentProductItemValueTotalWorkFlows = useSetRecoilState<number>(
    currentProductItemValueTotalWorkFlowsState
  );
  const [expanded, setExpanded] = useState<string | false>("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(
    t("products.offsetPrice.admin.logs")
  );
  const [pricingDefaultValue, setPricingDefaultValue] = useState<any>();
  const [workFlows, setWorkFlows] = useRecoilState(workFlowsState);
  const setCalculationExceptionsLogs = useSetRecoilState(
    calculationExceptionsLogsState
  );
  const selectedWorkFlow = useRecoilValue(selectedWorkFlowState);
  const [quantityTypes, setQuantityTypes] = useRecoilState(
    productQuantityTypesValuesState
  );

  const [calculationProgress, setCalculationProgress] = useRecoilState(
    calculationProgressState
  );
  const setCalculationResult = useSetRecoilState(calculationResultState);
  const jobDetails = useRecoilValue(jobDetailsState);
  const [jobActions, setJobActions] = useRecoilState(jobActionsState);
  const setOutSuppliers = useSetRecoilState(outsourceSuppliersState);
  const materialsEnumsValues = useRecoilValue(materialsCategoriesState);
  const [isLoadging, setLoading] = useRecoilState(isLoadgingState);
  const setLoadingWithLockScreen = useSetRecoilState(loadingWithShadowState);

  const [priceRecovery, setPriceRecovery] = useState(true);
  const [canCalculation, setCanCalculation] = useState(false);
  const setSelectParameterButton = useSetRecoilState(
    selectParameterButtonState
  );
  const [deviceCategory, setDeviceCategory] = useState("");
  const [deviceSize, setDeviceSize] = useState("");

  const [isActionEmployeeUpdated, setIsActionEmployeeUpdated] = useRecoilState(
    isActionEmployeeUpdatedState
  );
  const [isChangePriceForEdit, setIsChangePriceForEdit] = useRecoilState(
    isChangePriceForEditState
  );

  const [isJobNameValue, setIsJobNameValue] = useState(false);
  const [saveCurrentStation, setSaveCurrentStation] = useState(false);
  const onClickCloseCurrentStationModal = () => {
    setSaveCurrentStation(false);
  };
  const onClickOpenCurrentStationModal = () => {
    setSaveCurrentStation(true);
  };

  useEffect(() => {
    setValuesState([]);
  }, []);

  useEffect(() => {
    if (widgetType != EWidgetProductType.CREATE) setLoading(false);
  }, []);

  const onClickCloseChangePriceModal = () => {
    setIsChangePriceForEdit(false);
    setIsActionEmployeeUpdated(false);
    setIsJobNameValue(false);
  };
  useEffect(() => {
    if (quantityTypes?.length > 0) {
      setWorkTypes(quantityTypes);
    } else if (productTypesNumber === 1) {
      setWorkTypes([]);
    }
  }, [quantityTypes, productTypesNumber]);
  const {
    calculationResult,
    connectionId,
    startCalculationData,
    updatedSelectedWorkFlow,
    calculationExceptionsLogs,
    signalRPricingResult,
    calculationServerError,
    isCalculationFinished,
    setIsCalculationFinished,
  } = useCalculationsWorkFlowsSignalR();
  useEffect(() => {
    setProductItemValueByEdit({});
  }, []);

  useEffect(() => {
    const oldConnectionId = currentSignalRConnectionId;
    if (oldConnectionId && connectionId && connectionId !== oldConnectionId) {
      updateSignalRConnectionId(oldConnectionId, connectionId);
    }
    setCurrentSignalRConnectionId(connectionId);
  }, [connectionId]);

  const updateSignalRConnectionId = async (
    oldSignalRConnectionId,
    newSignalRConnectionId
  ) => {
    const res: any = await callApi(
      "POST",
      `/v1/erp-service/quote/update-product-item-value-signalr-connect-id`,
      {
        oldSignalRConnectionId: oldSignalRConnectionId,
        newSignalRConnectionId: newSignalRConnectionId,
      },
      false
    );
  };

  useEffect(() => {
    if (calculationResult && widgetType != EWidgetProductType.EDIT) {
      setCalculationResult(calculationResult);
    }
  }, [calculationResult]);

  useEffect(() => {
    if (calculationServerError) {
      setcalculationServerErrorState(true);
      setCalculationProgress({
        totalWorkFlowsCount: 0,
        currentWorkFlowsCount: 0,
      });
      setLoading(false);
    }
  }, [calculationServerError]);

  useEffect(() => {
    if (isCalculationFinished) {
      setCalculationProgress({
        totalWorkFlowsCount: 0,
        currentWorkFlowsCount: 0,
      });
    }
  }, [isCalculationFinished]);

  const [calculationServerErrorState, setcalculationServerErrorState] =
    useState(false);
  const [currentSignalRConnectionId, setCurrentSignalRConnectionId] =
    useRecoilState(currentCalculationConnectionId);
  const [currentCalculationSessionId, setCurrentCalculationSessionId] =
    useState<string>("");
  const [requestAbortController, setRequestAbortController] =
    useState<AbortController>(null);
  const [billingMethod, setBillingMethod] = useState<any>();
  const [graphicDesigner, setGraphicDesigner] = useState<any>();
  const [includeVAT, setIncludeVAT] = useState<boolean | null>(null);

  useEffect(() => {
    let copy = lodashClonedeep(subProducts);
    setSubProductsCopy(copy);
  }, [subProducts]);

  useEffect(() => {
    if (
      calculationResult &&
      calculationResult.productItemValueDraftId === currentCalculationSessionId
    ) {
      getSelectedWorkFlow();
    }
  }, [calculationResult, currentCalculationSessionId]);
  const [selectedWorkFlowCalculatedIsLoaded, setSelectedWorkFlowCalculatedIsLoaded] = useState<boolean>(false)

  const getSelectedWorkFlow = async () => {
    // setSelectedWorkFlowCalculatedIsLoaded(true)
    const docType = router?.query?.documentType ?? "0";
    const documentItemId = router?.query?.documentItemId;
    let url = `/v1/erp-service/productItemValueDrafts/get-selected-work-flow?signalRConnectionId=${connectionId}`;
    if (documentItemId) {
      url += `&documentItemId=${documentItemId}&docType=${docType}&restoreAdditonalProductPrice=${priceRecovery}`;
    }
    const res: any = await callApi("GET", url, {}, false);
    if (!res.success) {
      setCalculationProgress({
        totalWorkFlowsCount: 0,
        currentWorkFlowsCount: 0,
      });

      //setLoading(false);
      setcalculationServerErrorState(true);
      return;
    }

    const result = res?.data?.data?.data;
    setCalculationExceptionsLogs(result?.productItemValue?.exceptions);
    setCurrentProductItemValueDraftId(result.productItemValueDraftId);
    stIsFlowChanged(result.isFlowChanged);
    setAdditionalProductItems(result.additionalProductItems);
    setCalculationProgress({
      totalWorkFlowsCount: 0,
      currentWorkFlowsCount: 0,
    });
    setCurrentProductItemValueTotalWorkFlows(
      result.productItemValue.totalWorkFlows
    );
    setWorkFlows(result.productItemValue.workFlows);
    if (
      result.productItemValue &&
      result.productItemValue.workFlows &&
      result.productItemValue.workFlows.length > 0 &&
      !(
        widgetType == EWidgetProductType.EDIT &&
        currentProductItemValueTotalPrice
      )
    ) {
      const mySelectedWorkFlow = result.productItemValue.workFlows[0];
      if (
        mySelectedWorkFlow &&
        mySelectedWorkFlow.totalPrice &&
        mySelectedWorkFlow.totalPrice.values
      ) {
        setCurrentProductItemValueTotalPrice(
          parseFloat(mySelectedWorkFlow.totalPrice.values[0])
        );
      }
    }

    setJobActions(result?.productItemValue.actions);
    setCalculationResult(result);
    if (
      widgetType === EWidgetProductType.EDIT &&
      (isActionEmployeeUpdated || isJobNameValue)
    ) {
      setIsChangePriceForEdit(true);
      setIsActionEmployeeUpdated(false);
      setIsJobNameValue(false);
      setSelectedWorkFlowCalculatedIsLoaded(false)

    }
    setSelectedWorkFlowCalculatedIsLoaded(false)

  };

  const setSelectedWorkflow = (newWorkFlows, currentWorkFlows) => {
    newWorkFlows.forEach((flow) => {
      const isExists = currentWorkFlows.find((x) => x.id === flow.id);
      if (!isExists) {
        currentWorkFlows.push(flow);
      }
      if (flow.selected) {
        currentWorkFlows.forEach((f) => (f.selected = false));
      }
    });
    if (calculationResult?.monials) {
      calculationResult?.monials.forEach((m) => {
        const workFlow = currentWorkFlows.find((x) => x.id === m.workFlowId);
        if (workFlow) {
          workFlow.monials = m?.monials;
          workFlow.recommendationRang = m?.recommendationRang;
        }
      });
    }
    currentWorkFlows.sort((a, b) => b.monials - a.monials);

    let myselectedWorkFlow = currentWorkFlows?.find((x) => x.selected);
    if (
      !myselectedWorkFlow &&
      currentWorkFlows &&
      currentWorkFlows.length > 0
    ) {
      currentWorkFlows[0].selected = true;
    }
    myselectedWorkFlow = currentWorkFlows?.find(
      (x) => x.selected && x.isCompleteWorkFlow
    );
    if (
      myselectedWorkFlow &&
      myselectedWorkFlow.isCompleteWorkFlow &&
      myselectedWorkFlow.totalPrice &&
      myselectedWorkFlow.totalPrice.values &&
      !priceRecovery
    ) {
      setCurrentProductItemValueTotalPrice(
        parseFloat(myselectedWorkFlow.totalPrice.values[0])
      );
    }
    return currentWorkFlows;
  };

  useEffect(() => {
    if (
      signalRPricingResult &&
      signalRPricingResult.productItemValueDraftId ===
      currentCalculationSessionId
    ) {
      setLoading(false);
      const totalWorkFlowsCount = signalRPricingResult.totalWorkFlows;
      const currentWorkFlowsCount =
        signalRPricingResult.currentWorkFlowIndex >
          calculationProgress.currentWorkFlowsCount
          ? signalRPricingResult.currentWorkFlowIndex
          : calculationProgress.currentWorkFlowsCount + 1;
      if (
        signalRPricingResult.isCalculationFinished &&
        signalRPricingResult.totalPrice
      ) {
        setCurrentProductItemValueTotalPrice(
          parseFloat(signalRPricingResult.totalPrice)
        );
      }
      if (signalRPricingResult.isCalculationFinished) {
        setCalculationProgress({
          totalWorkFlowsCount: 0,
          currentWorkFlowsCount: 0,
        });
      } else {
        setCalculationProgress({
          totalWorkFlowsCount: totalWorkFlowsCount,
          currentWorkFlowsCount: currentWorkFlowsCount,
        });
      }
    }
  }, [signalRPricingResult]);

  useEffect(() => {
    if (startCalculationData && startCalculationData.isDevMode) {
      setWorkFlows([]);
      setCurrentProductItemValueTotalPrice(null);
      setJobActions([]);
      setIsCalculationFinished(false);
      setCalculationProgress({
        totalWorkFlowsCount: 0,
        currentWorkFlowsCount: 0,
      });
      setCurrentCalculationSessionId(
        startCalculationData.productItemValueDraftId
      );
    }
  }, [startCalculationData]);

  useEffect(() => {
    if (calculationExceptionsLogs) {
      setCalculationExceptionsLogs(calculationExceptionsLogs);
    }
  }, [calculationExceptionsLogs]);

  useEffect(() => {
    if (updatedSelectedWorkFlow) {
      if (workFlows.find((x) => x.id == updatedSelectedWorkFlow?.id)) {
        setWorkFlows(
          workFlows.map((flow) =>
            flow.id === updatedSelectedWorkFlow?.id
              ? updatedSelectedWorkFlow
              : {
                ...flow,
                selected: false,
              }
          )
        );
      } else {
        let temp = workFlows.map((flow) => {
          return { ...flow, selected: false };
        });
        temp.forEach((item) => {
          if (updatedSelectedWorkFlow.orginalBookPartId === item.id) {
            item.selected = true;
          }
          if (updatedSelectedWorkFlow.subWorkFlows) {
            updatedSelectedWorkFlow.subWorkFlows.forEach((subFlow) => {
              if (subFlow.orginalBookPartId === item.id) {
                item.selected = true;
              }
            });
          }
        });

        setWorkFlows([...temp, { ...updatedSelectedWorkFlow, selected: true }]);
      }
      if (
        updatedSelectedWorkFlow?.totalPrice &&
        updatedSelectedWorkFlow?.totalPrice?.values
      ) {
        if (
          widgetType === EWidgetProductType.EDIT &&
          (isActionEmployeeUpdated || isJobNameValue)
        ) {
          setIsChangePriceForEdit(true);
          setIsActionEmployeeUpdated(false);
          setIsJobNameValue(false);
        }
        setCurrentProductItemValueTotalPrice(
          +updatedSelectedWorkFlow?.totalPrice.values[0]
        );
      }
    }
  }, [updatedSelectedWorkFlow]);

  useEffect(() => {
    const total = quantityTypes.reduce((acc, val) => acc + val.quantity, 0);
    let subProductCopy = cloneDeep(subProducts);
    subProductCopy.forEach((subProduct) => {
      if (!subProduct.type) {
        subProduct.parameters.forEach((parameter) => {
          if (
            parameter.parameterId === "4991945c-5e07-4773-8f11-2e3483b70b53"
          ) {
            parameter.values = [total + ""];
          }
        });
      }
    });
    setSubProducts(subProductCopy);
  }, [quantityTypes]);

  const selectBtnTypeToAction = (
    parameter,
    sectionId,
    subSectionId,
    index,
    paameterType,
    actionIndex
  ) => {
    if (parameter?.buttonAction === EButtonTypes.GALLERY_MODAL) {
      setSelectParameterButton({
        parameter,
        sectionId,
        subSectionId,
        index,
        paameterType,
        actionIndex,
      });
      onOpeneGalleryModal();
    }
  };

  const findLargestActionIndex = (params) => {
    return params.reduce(
      (maxIndex, param) => Math.max(maxIndex, param.actionIndex),
      -1
    );
  };

  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((param) => {
      if (!uniqueIds.has(param.id)) {
        uniqueIds.add(param.id);
        return true;
      }
      return false;
    });
  }

  const duplicateSection = (item) => {
    let defaultProductTemplateCopy = cloneDeep(defaultProductTemplate);
    const productTemplateCopy = cloneDeep(productTemplate);

    const section = defaultProductTemplateCopy.sections.find(
      (x) => x.id === item.id
    );
    const sectionCopy = cloneDeep(section);
    const numberOfCopies = productTemplateCopy.sections.filter(
      (x) => x.duplicatedFromSectionId === item.id
    ).length;
    if (!numberOfCopies) {
      //section.name = section.name + " 1";
      section.index = 0;
    }
    sectionCopy.index = numberOfCopies + 1;
    sectionCopy.name = sectionCopy.name + " " + (sectionCopy.index + 1);
    sectionCopy.duplicatedFromSectionId = item.id;
    sectionCopy.id = uuidv4();
    sectionCopy.subSections.forEach((sub) => {
      sub.duplicatedFromSubSectionId = sub.id;
      sub.id = sub.id;
      if (sub.type) {
        sub.type = sub.type + numberOfCopies + 1;
      }
    });
    const sectionsArr = [];
    productTemplateCopy.sections.forEach((sec) => {
      sectionsArr.push(sec);
      if (!numberOfCopies && sec.id === item.id) {
        sectionsArr.push(sectionCopy);
      } else if (numberOfCopies && sec.index == numberOfCopies) {
        sectionsArr.push(sectionCopy);
      }
    });
    productTemplateCopy.sections = sectionsArr;
    initProduct(productTemplateCopy, allMaterials);
  };

  const removeSection = (item) => {
    let temp = cloneDeep(productTemplate);
    temp.sections = temp.sections.filter((x) => x.id !== item.id);
    initProduct(temp, allMaterials);
    const updatedSubProducts = subProducts.filter(
      (subProduct) => subProduct.sectionId !== item.id
    );
    setSubProducts(updatedSubProducts);
  };

  const duplicateParameters = (mySubSection) => {
    let temp = cloneDeep(productTemplate);
    let myId = mySubSection?.id;
    let largestIndex = findLargestActionIndex(mySubSection.parameters);
    const duplicatedParameters = mySubSection.parameters.map((parameter) => {
      const duplicatedParameter = { ...parameter };
      duplicatedParameter.actionIndex = largestIndex + 1;
      if (duplicatedParameter.relatedParameters) {
        duplicatedParameter.relatedParameters.forEach((relatedParameter) => {
          relatedParameter.actionIndex = duplicatedParameter.actionIndex;
        });
      }
      return duplicatedParameter;
    });
    const uniqueParameters = removeDuplicates(duplicatedParameters);
    temp.sections.forEach((section) => {
      section.subSections.forEach((subSection) => {
        if (subSection.id === myId) {
          subSection.parameters = cloneDeep(subSection.parameters).concat(
            uniqueParameters
          );
        }
      });
    });
    initProduct(temp, allMaterials);
  };

  useEffect(() => {
    if (productTemplate && productTemplate.sections?.length > 0) {
      let temp = [];
      let activeSectionTemp = [];

      productTemplate.sections.map((section, sectionIndex) => {
        return section.subSections?.map((subSection) => {
          return subSection.parameters?.map((parameter) => {
            const index = temp.findIndex(
              (item) =>
                item.id === parameter.id &&
                item?.code === parameter.code &&
                item.actionIndex === parameter.actionIndex
            );
            if (index !== -1) {
              temp[index] = {
                ...temp[index],
              };
            } else {
              if (parameter.isRequired) {
                temp.push(parameter);
                if (sectionIndex === activeIndex) {
                  activeSectionTemp.push(parameter);
                }
              }
            }
          });
        });
      });
      // Filter out items where isHidden === true
      temp = temp.filter((item) => !item.isHidden);
      setIsRequiredParameters(temp);
      setActiveSectionRequiredParameters(activeSectionTemp);
    }
  }, [productTemplate, activeIndex]);

  const [relatedParameters, setRelatedParameters] = useState([]);
  const [underParameterIds, setUnderParameterIds] = useState([]);

  useEffect(() => {
    if (!isSetTemplete) {
      if (productTemplate && productTemplate?.sections?.length > 0) {
        let sectionData: any = cloneDeep(productTemplate?.sections);
        const typeMap = {};
        let underParameterIdsArray = [];
        const subProductsArray = cloneDeep(subProducts);

        sectionData.forEach((section) => {
          section.subSections.forEach((subSection) => {
            let subProduct = subProductsArray.find(
              (sub) => sub.type == subSection.type
            );
            if (!subProduct) {
              subProduct = {
                type: subSection.type,
                parameters: [],
                sectionId: section.id,
                sectionName: section.name,
              };
              subProductsArray.push(subProduct);
            }
            let temp = [];
            subSection.parameters.forEach((parameter) => {
              if (parameter?.isHidden && !parameter?.isHiddenInSetting) {
                if (parameter.isUnderParameterId !== null) {
                  underParameterIdsArray.push({
                    underParameterId: parameter.isUnderParameterId,
                    myParameter: parameter,
                    sectionId: section.id,
                    subSectionId: subSection.id,
                  });
                }
               return;
              }
              parameter.relatedParameters.forEach((x) => {
                x.sectionId = section.id;
                x.subSectionId = subSection.id;
                x.actionIndex = parameter?.actionIndex;
              });
              if (parameter?.settingParameters?.length > 0) {
                const parameterValue = parameter.valuesConfigs.find(
                  (x) => x.isDefault === true
                );
                if (
                  parameterValue &&
                  parameterValue.selectedParameterValues &&
                  parameterValue.selectedParameterValues.length > 0
                ) {
                  parameterValue.selectedParameterValues.forEach(
                    (selectedParam) => {
                      if (
                        selectedParam.valueIds &&
                        selectedParam.valueIds.length > 0
                      ) {
                        const param = parameter.settingParameters.find(
                          (param) => param.id === selectedParam.parameterId
                        );
                        let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === param.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  param?.actionIndex  );
                        if(!existsSubProductParameter){
                          subProduct.parameters.push({
                            parameterId: param.id,
                            sectionId: section.id,
                            subSectionId: subSection.id,
                            ParameterType: param.parameterType,
                            isQuantityParameter: param.isQuantityParameter,
                            parameterName: param.name,
                            actionId: param.actionId,
                            values: selectedParam.valueIds,
                            valueIds: selectedParam.valueIds,
                            actionIndex: param?.actionIndex,
                            parameterCode: param?.code,
                            isGeneralInfoInProductionFloor:
                            param?.isGeneralInfoInProductionFloor,
                          });
                        }
                        
                      } else if (productItemValueByEdit) {
                        const parameterIdsToExtract = [
                          "e1dfbaab-977e-4267-9e4e-d733f49ee20d",
                          "8b43efba-f28c-4e73-8fa0-cc64f3ea06f8",
                          "8eff3238-a321-48f3-85eb-c14afaccbff3",
                        ];

                        parameterIdsToExtract.forEach((parameterId) => {
                          const parameter =
                            productItemValueByEdit?.itemParmetersValues?.find(
                              (item) => item.parameterId === parameterId
                            );
                          if (parameter) {
                            subProduct.parameters.push(parameter);
                          }
                        });
                      }
                    }
                  );
                }
              }

              if (parameter.isUnderParameterId !== null) {
                underParameterIdsArray.push({
                  underParameterId: parameter.isUnderParameterId,
                  myParameter: parameter,
                  sectionId: section.id,
                  subSectionId: subSection.id,
                });
              }
              const isParameterExits = subProduct.parameters.find(
                (param) =>
                  param.parameterId === parameter?.id &&
                  param.subSectionId === subSection.id &&
                  param.actionIndex === parameter?.actionIndex
              );
              let isSetDefaultValue = true;
              if (
                parameter?.parameterType === EParameterTypes.SWITCH &&
                parameter?.defaultValue === "false"
              ) {
                isSetDefaultValue = true;
              }
              if (!isParameterExits && isSetDefaultValue) {
                if (
                  parameter?.parameterType === EParameterTypes.INPUT_NUMBER ||
                  parameter?.parameterType === EParameterTypes.INPUT_TEXT ||
                  parameter?.parameterType === EParameterTypes.SWITCH
                ) {
                  if (parameter?.defaultValue?.length > 0) {
                    const defaultValue = parameter?.defaultValue;
                    let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );
                    if(!existsSubProductParameter){
                      subProduct.parameters.push({
                        parameterId: parameter?.id,
                        parameterName: parameter?.name,
                        actionId: parameter?.actionId,
                        parameterType: parameter?.parameterType,
                        isQuantityParameter: parameter?.isQuantityParameter,
                        ...(defaultValue?.length > 0 && {
                          values: [defaultValue],
                        }),
                        sectionId: section?.id,
                        subSectionId: subSection?.id,
                        actionIndex: parameter?.actionIndex,
                        parameterCode: parameter?.code,
                        valuesConfigs: parameter?.valuesConfigs,
                        unitKey: parameter?.unitKey,
                        unitType: parameter?.unitType,
                        isGeneralInfoInProductionFloor:
                        parameter?.isGeneralInfoInProductionFloor,
                      });
                    }
                  }
                } else if (
                  parameter?.parameterType === EParameterTypes.DROP_DOWN_LIST
                ) {
                  const value = parameter?.valuesConfigs?.find(
                    (item) => item?.isDefault == true
                  );
                  if (value) {
                    const data = materialsEnumsValues.find((item) => {
                      return compareStrings(
                        item.name,
                        parameter?.materialPath[0]
                      );
                    });
                    let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );
                    if(!existsSubProductParameter){
                      subProduct.parameters.push({
                        parameterId: parameter?.id,
                        parameterName: parameter?.name,
                        actionId:
                            value?.activateAction === true
                                ? parameter?.actionId
                                : null,
                        ...(data?.id > 0 && { material: data?.id }),
                        parameterType: parameter?.parameterType,
                        isQuantityParameter: parameter?.isQuantityParameter,
                        ...(value && {
                          valueIds: [value?.id],
                          values: [value?.updateName],
                        }),
                        sectionId: section?.id,
                        subSectionId: subSection?.id,
                        actionIndex: parameter?.actionIndex,
                        parameterCode: parameter?.code,
                        valuesConfigs: parameter?.valuesConfigs,
                        unitKey: parameter?.unitKey,
                        unitType: parameter?.unitType,
                        isGeneralInfoInProductionFloor:
                        parameter?.isGeneralInfoInProductionFloor,
                      });
                    }
                   
                  }
                } else if (
                  parameter?.parameterType ===
                  EParameterTypes.SELECT_MATERIALS ||
                  parameter?.parameterType ===
                  EParameterTypes.ADVERTISING_PRODUCT_CATEGORY ||
                  parameter?.parameterType ===
                  EParameterTypes.ADVERTISING_PRODUCT_NAME ||
                  parameter?.parameterType === EParameterTypes.BUTTON
                ) {
                  const value = parameter?.valuesConfigs?.find(
                    (item) => item?.isDefault == true
                  );
                  // when the parameter is required and the parameter is material select and if there is only one option select it automatically
                  if (
                    !value &&
                    parameter?.valuesConfigs &&
                    parameter?.valuesConfigs?.length === 1 &&
                    parameter?.isRequired
                  ) {
                    let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );
                    if(!existsSubProductParameter){
                      subProduct.parameters.push({
                        parameterId: parameter?.id,
                        parameterName: parameter?.name,
                        actionId: parameter?.actionId,
                        parameterType: parameter?.parameterType,
                        isQuantityParameter: parameter?.isQuantityParameter,
                        valueIds: parameter?.valuesConfigs[0]?.values,
                        values: [parameter?.valuesConfigs[0]?.updateName],
                        sectionId: section?.id,
                        subSectionId: subSection?.id,
                        actionIndex: parameter?.actionIndex,
                        parameterCode: parameter?.code,
                        valuesConfigs: parameter?.valuesConfigs,
                        unitKey: parameter?.unitKey,
                        unitType: parameter?.unitType,
                        isGeneralInfoInProductionFloor:
                        parameter?.isGeneralInfoInProductionFloor,
                      });
                    }
                    
                  }

                  if (
                    value &&
                    value.materialValueIds &&
                    value.materialValueIds.length > 0
                  ) {
                    const data = materialsEnumsValues.find((item) => {
                      return compareStrings(
                        item.name,
                        parameter?.materialPath[0]
                      );
                    });
                    const defValue = value.materialValueIds[0].valueId;
                    if (defValue) {
                      let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );
                      if(!existsSubProductParameter){
                        subProduct.parameters.push({
                          parameterId: parameter?.id,
                          parameterName: parameter?.name,
                          actionId:
                              value?.activateAction === true
                                  ? parameter?.actionId
                                  : null,
                          ...(data?.id > 0 && { material: data?.id }),
                          parameterType: parameter?.parameterType,
                          isQuantityParameter: parameter?.isQuantityParameter,
                          ...(value && {
                            valueIds: [defValue],
                            values: [value?.updateName],
                          }),
                          sectionId: section?.id,
                          subSectionId: subSection?.id,
                          actionIndex: parameter?.actionIndex,
                          parameterCode: parameter?.code,
                          valuesConfigs: parameter?.valuesConfigs,
                          unitKey: parameter?.unitKey,
                          unitType: parameter?.unitType,
                          isGeneralInfoInProductionFloor:
                          parameter?.isGeneralInfoInProductionFloor,
                        });
                      }
                     
                    }
                  } else if (value && parameter.code === "DieCut") {
                    const existingParameters =
                      productItemValueByEdit?.itemParmetersValues || [];
                    const isDieCutExisting = existingParameters.find(
                      (param) => param?.parameterCode === "DieCut"
                    );
                    if (isDieCutExisting || existingParameters.length === 0) {
                      const nameValue = value?.additionalAttribute?.find(
                        (attr) => attr.valueId === "name"
                      )?.value;
                      let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );

                      if(!existsSubProductParameter){
                        const newParameter = {
                          parameterId: parameter?.id,
                          parameterName: parameter?.name,
                          actionId: value?.activateAction
                              ? parameter?.actionId
                              : null,
                          parameterType: parameter?.parameterType,
                          isQuantityParameter: parameter?.isQuantityParameter,
                          valueIds: [value?.id],
                          values: [nameValue],
                          sectionId: section?.id,
                          subSectionId: subSection?.id,
                          actionIndex: parameter?.actionIndex,
                          parameterCode: parameter?.code,
                          valuesConfigs: parameter?.valuesConfigs,
                          unitKey: parameter?.unitKey,
                          unitType: parameter?.unitType,
                          isGeneralInfoInProductionFloor:
                          parameter?.isGeneralInfoInProductionFloor,
                        };

                        subProduct.parameters.push(newParameter);
                      }
                      
                    }
                  } else if (!value && parameter.code === "DieCut") {
                    const existingParameters =
                      productItemValueByEdit?.itemParmetersValues || [];
                    const isDieCutExisting = existingParameters.find(
                      (param) => param?.parameterCode === "DieCut"
                    );
                    if (isDieCutExisting) {
                      let materialSelected = allMaterials?.find(
                        (item) =>
                          item?.pathName === isDieCutExisting?.parameterCode
                      )?.data;
                      const additionalAttribute = materialSelected?.find(
                        (item) =>
                          item?.valueId === isDieCutExisting?.valueIds[0]
                      )?.additionalAttribute;
                      const nameValue = additionalAttribute?.find(
                        (attr) => attr.valueId === "name"
                      )?.value;
                      let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );
                      if(!existsSubProductParameter){
                        subProduct.parameters.push({
                          parameterId: parameter?.id,
                          parameterName: parameter?.name,
                          actionId:
                              value?.activateAction === true
                                  ? parameter?.actionId
                                  : null,
                          parameterType: parameter?.parameterType,
                          isQuantityParameter: parameter?.isQuantityParameter,
                          ...(isDieCutExisting?.valueIds[0] && {
                            valueIds: [isDieCutExisting?.valueIds[0]],
                            values: [nameValue],
                          }),
                          sectionId: section?.id,
                          subSectionId: subSection?.id,
                          actionIndex: parameter?.actionIndex,
                          parameterCode: parameter?.code,
                          valuesConfigs: parameter?.valuesConfigs,
                          unitKey: parameter?.unitKey,
                          unitType: parameter?.unitType,
                          isGeneralInfoInProductionFloor:
                          parameter?.isGeneralInfoInProductionFloor,
                        });
                      }
                      
                    }
                  } else if (value && parameter.code === "DieKissCut") {
                    const existingParameters =
                      productItemValueByEdit?.itemParmetersValues || [];
                    const isDieCutExisting = existingParameters.find(
                      (param) => param?.parameterCode === "DieKissCut"
                    );
                    if (isDieCutExisting || existingParameters.length === 0) {
                      const nameValue = value?.additionalAttribute?.find(
                        (attr) => attr.valueId === "name"
                      )?.value;
                      let existsSubProductParameter = subProduct.parameters.find(x=>x.parameterId === parameter.id && x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex  );
                      if(!existsSubProductParameter){
                        subProduct.parameters.push({
                          parameterId: parameter?.id,
                          parameterName: parameter?.name,
                          actionId:
                              value?.activateAction === true
                                  ? parameter?.actionId
                                  : null,
                          parameterType: parameter?.parameterType,
                          isQuantityParameter: parameter?.isQuantityParameter,
                          ...(value && {
                            valueIds: [value?.id],
                            values: [nameValue],
                          }),
                          sectionId: section?.id,
                          subSectionId: subSection?.id,
                          actionIndex: parameter?.actionIndex,
                          parameterCode: parameter?.code,
                          valuesConfigs: parameter?.valuesConfigs,
                          unitKey: parameter?.unitKey,
                          unitType: parameter?.unitType,
                          isGeneralInfoInProductionFloor:
                          parameter?.isGeneralInfoInProductionFloor,
                        });
                      }
                      
                    }
                  } else if (!value && parameter.code === "DieKissCut") {
                    const existingParameters =
                      productItemValueByEdit?.itemParmetersValues || [];
                    const isDieCutExisting = existingParameters.find(
                      (param) => param?.parameterCode === "DieKissCut"
                    );
                    if (isDieCutExisting) {
                      let materialSelected = allMaterials?.find(
                        (item) =>
                          item?.pathName === isDieCutExisting?.parameterCode
                      )?.data;
                      const additionalAttribute = materialSelected?.find(
                        (item) =>
                          item?.valueId === isDieCutExisting?.valueIds[0]
                      )?.additionalAttribute;
                      const nameValue = additionalAttribute?.find(
                        (attr) => attr.valueId === "name"
                      )?.value;
                      if(!subProduct.parameters.findIndex(x=>x.parameterId === parameter?.id &&  x.sectionId === section.id &&  x.subSectionId === subSection.id  && x.actionIndex ===  parameter?.actionIndex)){
                        subProduct.parameters.push({
                          parameterId: parameter?.id,
                          parameterName: parameter?.name,
                          actionId:
                              value?.activateAction === true
                                  ? parameter?.actionId
                                  : null,
                          parameterType: parameter?.parameterType,
                          isQuantityParameter: parameter?.isQuantityParameter,
                          ...(isDieCutExisting?.valueIds[0] && {
                            valueIds: [isDieCutExisting?.valueIds[0]],
                            values: [nameValue],
                          }),
                          sectionId: section?.id,
                          subSectionId: subSection?.id,
                          actionIndex: parameter?.actionIndex,
                          parameterCode: parameter?.code,
                          valuesConfigs: parameter?.valuesConfigs,
                          unitKey: parameter?.unitKey,
                          unitType: parameter?.unitType,
                          isGeneralInfoInProductionFloor:
                          parameter?.isGeneralInfoInProductionFloor,
                        });
                      }
                     
                    }
                  }
                } else if (
                  parameter?.parameterType ===
                  EParameterTypes.SELECT_CHILDS_PARAMETERS
                ) {
                  const defaultObject = parameter.valuesConfigs.find(
                    (item) => item.isDefault === true
                  );
                  parameter?.childsParameters.forEach((parameter) => {
                    const parameterId = parameter.id;
                    if (
                      defaultObject?.values.hasOwnProperty(parameterId) &&
                      defaultObject?.values
                    ) {
                      parameter.defaultValue =
                        defaultObject?.values[parameterId];
                    }
                  });
                  if (defaultObject) {
                    subProduct.parameters.push({
                      parameterId: parameter?.id,
                      parameterName: parameter?.name,
                      actionId: parameter?.actionId,
                      parameterType: parameter?.parameterType,
                      isQuantityParameter: parameter?.isQuantityParameter,
                      ...(defaultObject && {
                        valueIds: [defaultObject?.id],
                        values: [defaultObject?.updateName],
                      }),
                      sectionId: section?.id,
                      subSectionId: subSection?.id,
                      actionIndex: parameter?.actionIndex,
                      parameterCode: parameter?.code,
                      valuesConfigs: parameter?.valuesConfigs,
                      unitKey: parameter?.unitKey,
                      unitType: parameter?.unitType,
                      isGeneralInfoInProductionFloor:
                      parameter?.isGeneralInfoInProductionFloor,
                    });
                    
                    parameter?.childsParameters?.map((item) => {
                      const childParam = subSection.parameters.find(
                        (x) => x.id === item.id
                      );
                      const sizeParameter = subProduct.parameters.find(
                        (item) => item.parameterCode === "size"
                      );
                      const sizeParameterValueConfig =
                        parameter.valuesConfigs.find(
                          (x) => x.id === sizeParameter?.valueIds[0]
                        );
                      let isDisabled = false;
                      if (
                        sizeParameterValueConfig &&
                        Object.keys(sizeParameterValueConfig.values).length > 0
                      ) {
                        isDisabled = true;
                      }
                      subProduct.parameters.push({
                        parameterId: childParam?.id,
                        parameterName: childParam?.name,
                        actionId: childParam?.actionId,
                        parameterType: childParam?.parameterType,
                        isQuantityParameter: childParam?.isQuantityParameter,
                        values: item.defaultValue
                            ? [item?.defaultValue]
                            : [childParam?.defaultValue],
                        sectionId: section?.id,
                        subSectionId: subSection?.id,
                        actionIndex: childParam?.actionIndex,
                        parameterCode: childParam?.code,
                        valuesConfigs: childParam?.valuesConfigs,
                        unitKey: childParam?.unitKey,
                        unitType: childParam?.unitType,
                        isDisabled: isDisabled,
                        isGeneralInfoInProductionFloor:
                        childParam?.isGeneralInfoInProductionFloor,
                      });
                      
                    });
                  }
                }
              }
              processRelatedParameters2(
                parameter,
                subSection,
                section,
                productTemplate,
                subProductsArray
              );
            });
            if (temp.length > 0) {
              if (!typeMap[subSection.type]) {
                typeMap[subSection.type] = {
                  type: subSection.type,
                  parameters: temp,
                  sectionId: section.id,
                  sectionName: section.name,
                };
              } else {
                typeMap[subSection.type].parameters.push(...temp);
              }
            }
          });
        });
        subProductsArray.forEach((subProduct) => {
          subProduct.parameters.forEach((parameter) => {
            if (parameter.parameterCode === "devicecategory") {
              setDeviceCategory(parameter?.valueIds[0]);
            } else if (parameter?.parameterCode === "devicesize") {
              setDeviceSize(parameter?.valueIds[0]);
            }
          });
        });
        const filteredSubProductsArray = subProductsArray.map((subProduct) => {
          return {
            ...subProduct,
            parameters: subProduct.parameters.filter((parameter) => {
              return !(
                parameter.values[0] === "false" || parameter.values[0] === null
              );
            }),
          };
        });
        setProductTemplate(productTemplate);
        setSubProducts(filteredSubProductsArray);
        setUnderParameterIds(underParameterIdsArray);
        setIsSetTemplete(true);
      }
    }
  }, [materialsEnumsValues, allMaterials, productTemplate]);

  const initProduct = (product, materials) => {
    if (product && product?.sections?.length > 0) {
      let sectionData: any = product.sections;
      let relatedParametersArray = [];
      let underParameterIdsArray = [];

      sectionData.forEach((section) => {
        section.subSections.forEach((subSection) => {
          subSection.parameters
            .forEach((parameter) => {
              if (parameter.relatedParameter) {
                parameter.relatedParameter.forEach((relatedParameter) => {
                  relatedParameter.actionIndex = parameter.actionIndex;
                });
              }
              parameter.relatedParameters.forEach((relatedParameter) => {
                // Check if the relatedParameter's parameterId exists in parameters
                const isParameterIdExisting = subSection.parameters.some(
                  (p) => p.id === relatedParameter.parameterId
                );
                if (isParameterIdExisting) {
                  relatedParametersArray.push(relatedParameter);
                }
              });
              const relatedToParameter = subSection.parameters.find(
                (subProductsParameter) =>
                  subProductsParameter?.relatedParameters?.find(
                    (p) => p.parameterId === parameter.id && p.actionIndex === parameter.actionIndex
                  )
              );
              if (relatedToParameter && relatedToParameter.isHidden) {
                parameter.isHidden = true;
                const unHiddenParameterInSubSection =
                  subSection.parameters.find((x) => !x.isHidden);
                if (!unHiddenParameterInSubSection) {
                  subSection.isHidden = true;
                }
                return;
              }
              let defaultValue = relatedToParameter?.defaultValue;
              if (
                relatedToParameter &&
                relatedToParameter.valuesConfigs &&
                relatedToParameter.valuesConfigs.length > 0
              ) {
                let defualtValueConfig = relatedToParameter.valuesConfigs.find(
                  (x) => x.isDefault
                );
                if (
                  defualtValueConfig &&
                  defualtValueConfig.values &&
                  defualtValueConfig.values.length > 0
                ) {
                  defaultValue = defualtValueConfig.values[0];
                } else if (defualtValueConfig) {
                  defaultValue = defualtValueConfig.id;
                }
              }
              const targetSubProduct = subProducts.find(
                  (item) => item.type === subSection?.type
              );
              if(relatedToParameter && targetSubProduct && targetSubProduct.parameters){
                 let subProductParameter = targetSubProduct.parameters.find(
                     (item) =>
                         item.parameterId === relatedToParameter.id &&
                         item.sectionId === section.id &&
                         item.subSectionId === subSection.id &&
                         item.actionIndex === relatedToParameter.actionIndex
                 );
                 if(subProductParameter && subProductParameter.valueIds && subProductParameter.valueIds.length > 0){
                   defaultValue = subProductParameter.valueIds[0]
                 }
              }
              if (relatedToParameter && (!defaultValue || defaultValue === "false")) {
                 parameter.isHidden = true;
              }
              const parameterDefaultValue = parameter.valuesConfigs?.find(
                  (x) => x.isDefault
              );
              
              if(relatedToParameter && relatedToParameter.relatedParameters ){
                let relationConfigs = relatedToParameter.relatedParameters.find(x=>x.parameterId === parameter.id);
                if(relationConfigs && relationConfigs.activateByAllValues && !defaultValue ){
                  parameter.isHidden = true;
                }
                else if(relationConfigs && relationConfigs.activateByAllValues && defaultValue){
                  parameter.isHidden = false;
                }
                else if(!(relationConfigs && relationConfigs.selectedValueIds && defaultValue && relationConfigs.selectedValueIds.find(x=> x === defaultValue ))){
                  parameter.isHidden = true;
                }
              }
              if (!relatedToParameter) {
                if (
                  (parameter?.parameterType ===
                    EParameterTypes.DROP_DOWN_LIST ||
                    parameter?.parameterType ===
                    EParameterTypes.SELECT_MATERIALS ||
                    parameter?.parameterType ===
                    EParameterTypes.ADVERTISING_PRODUCT_CATEGORY ||
                    parameter?.parameterType ===
                    EParameterTypes.ADVERTISING_PRODUCT_NAME) &&
                  (!parameter?.valuesConfigs ||
                    parameter?.valuesConfigs.length === 0)
                ) {
                  //parameter.isHidden = true;
                  //return;
                }
              }
              if (parameter.materialPath && parameter.materialPath.length > 0) {
                if (parameter.materialPath.length == 1) {
                  const materialData = materials?.find((x) =>
                    compareStrings(x.pathName, parameter.materialPath[0])
                  )?.data;
                  if (!parameter.valuesConfigs) {
                    parameter.valuesConfigs = [];
                  }
                  parameter.valuesConfigs.forEach((val) => {
                    if (
                      val.materialValueIds &&
                      val.materialValueIds.length > 0
                    ) {
                      val.valueIds = [];
                      val.values = [];
                      val.materialValueIds.forEach((materialValue) => {
                        val.valueIds.push(materialValue.valueId);
                        val.values.push(materialValue.valueId);
                      });
                    }
                  });
                  if (
                    parameter.code === "DieCut" ||
                    parameter.code === "DieKissCut"
                  ) {
                    if (parameter.valuesConfigs.length === 0) {
                      parameter.valuesConfigs = materialData?.map((data) => ({
                        id: data.valueId,
                        isHidden: false,
                        isDefault: false,
                        updateName: null,
                        activateAction: false,
                        isDeleted: false,
                        materialValueIds: null,
                        values: null,
                        selectedParameterValues: null,
                        code: null, // Add code if needed
                        additionalAttribute: data.additionalAttribute,
                        currency: data.currency,
                      }));
                    } else {
                      // If valueConfigs has objects, update it based on materialData
                      parameter.valuesConfigs = materialData?.map((data) => {
                        const existingConfig = parameter.valuesConfigs.find(
                          (config) => config.id === data.valueId
                        );
                        return existingConfig
                          ? {
                            ...existingConfig,
                            additionalAttribute: data.additionalAttribute,
                            currency: data.currency,
                          }
                          : {
                            id: data.valueId,
                            isHidden: false,
                            isDefault: false,
                            updateName: null,
                            activateAction: false,
                            isDeleted: false,
                            materialValueIds: null,
                            values: null,
                            selectedParameterValues: null,
                            code: null, // Add code if needed
                            additionalAttribute: data.additionalAttribute,
                            currency: data.currency,
                          };
                      });
                    }
                  } else {
                    parameter.valuesConfigs = parameter.valuesConfigs.filter(
                      (x) => x.valueIds && x.valueIds.length > 0
                    );
                    if (materialData) {
                      materialData.forEach((val) => {
                        const existsValue = parameter.valuesConfigs.find(
                          (x) =>
                            x.valueIds &&
                            x.valueIds.length > 0 &&
                            x.valueIds[0] === val.valueId
                        );
                        if (!existsValue) {
                          parameter.valuesConfigs.push({
                            id: val.valueId,
                            activateAction: false,
                            isDefault: false,
                            isDeleted: false,
                            isHidden: false,
                            materialValueIds: null,
                            selectedParameterValues: null,
                            updateName: val.value,
                            values: [val.valueId],
                            additionalAttribute: val?.additionalAttribute,
                            currency: val?.currency,
                          });
                        } else {
                          existsValue.id = val.valueId;
                          existsValue.updateName = val.value;
                          (existsValue.additionalAttribute =
                            val?.additionalAttribute),
                            (existsValue.currency = val?.currency);
                        }
                      });
                    }
                  }
                }

                
                
                if (parameterDefaultValue) {
                  
                  const materialPath =
                    parameter.materialPath[parameter.materialPath.length - 1];
                  const materialRelatedParameters =
                    subSection.parameters.filter(
                      (x) =>
                        x.id !== parameter.id &&
                        x.actionIndex === parameter.actionIndex &&
                        x.materialPath?.find((y) =>
                          compareStrings(y, materialPath)
                        )
                    );
                  if (
                    materialRelatedParameters &&
                    materialRelatedParameters.length > 0
                  ) {
                    let param = materialRelatedParameters[0];
                    if (param.materialPath && param.materialPath.length > 0) {
                      const index = param.materialPath.findIndex((x) =>
                        compareStrings(x, materialPath)
                      );
                      let allMaterialsCopy = materials;
                      let paramMaterialValues = [];
                      if (index > 0) {
                        allMaterialsCopy = allMaterialsCopy?.find((x) =>
                          compareStrings(x.pathName, param.materialPath[0])
                        )?.data;
                        for (let i = 0; i <= index; i++) {
                          const prevPath = param.materialPath[i];
                          const prevPathParam = subSection.parameters.find(
                            (x) =>
                              x.materialPath &&
                              x.materialPath.length > i &&
                              x.materialPath[i] === prevPath &&
                              x.actionIndex === parameter.actionIndex
                          );
                          let prevPathParamDefaultValue =
                            prevPathParam.valuesConfigs.find(
                              (x) => x.isDefault
                            );

                          if (
                            prevPathParamDefaultValue &&
                            prevPathParamDefaultValue?.values &&
                            prevPathParamDefaultValue?.values.length
                          ) {
                            allMaterialsCopy = allMaterialsCopy?.find(
                              (x) =>
                                x.valueId ===
                                prevPathParamDefaultValue?.values[0]
                            )?.data;
                          } else {
                            allMaterialsCopy = [];
                          }
                        }
                        paramMaterialValues = allMaterialsCopy;
                      } else if (index === 0) {
                        allMaterialsCopy = allMaterialsCopy.find((x) =>
                          compareStrings(x.pathName, materialPath)
                        )?.data;
                        paramMaterialValues = allMaterialsCopy?.find((x) =>
                            parameterDefaultValue?.values?.find((y) => y === x.valueId)
                        )?.data;
                      }
                      if (
                        index != -1 &&
                        index < param.materialPath.length - 1
                      ) {
                        param.valuesConfigs.forEach((val) => {
                          if (
                            val.materialValueIds &&
                            val.materialValueIds.length > 0
                          ) {
                            val.valueIds = [];
                            val.values = [];
                            val.materialValueIds.forEach((materialValue) => {
                              val.valueIds.push(materialValue.valueId);
                              val.values.push(materialValue.valueId);
                            });
                          }
                        });
                        param.valuesConfigs = param.valuesConfigs.filter(
                          (x) => x.values && x.values.length > 0
                        );
                        for(let i = 0 ; i < paramMaterialValues?.length;i++){
                          let val = paramMaterialValues[i];
                          const existsValue = param.valuesConfigs?.find(
                              (x) =>
                                  (x.valueIds &&
                                  x.valueIds.length > 0 &&
                                  x.valueIds[0] === val.valueId) || (x.id === val.valueId)
                          );
                          if (!existsValue) {
                            param.valuesConfigs.push({
                              id: val.valueId,
                              activateAction: false,
                              isDefault: false,
                              isDeleted: false,
                              isHidden: false,
                              materialValueIds: null,
                              selectedParameterValues: null,
                              updateName: val.value,
                              values: [val.valueId],
                              additionalAttribute: val?.additionalAttribute,
                              currency: val?.currency,
                            });
                          }
                          else {
                            existsValue.id = val.valueId;
                            existsValue.updateName = val.value;
                            (existsValue.additionalAttribute =
                                val?.additionalAttribute),
                                (existsValue.currency = val?.currency);
                          }
                        }
                        if (
                          param?.valuesConfigs &&
                          param?.valuesConfigs?.length === 1 &&
                          param?.isRequired
                        ) {
                          param.valuesConfigs[0].isDefault = true;
                        }
                      }
                    }
                  }
                }
              }
              if (parameter?.parameterType === EParameterTypes.SWITCH) {
                parameter.isRequired = false;
              }
              // processRelatedParameters2(parameter, subSection, section, productTemplate, subProducts)
              parameter.relatedParameters.forEach((x) => {
                x.sectionId = section.id;
                x.subSectionId = subSection.id;
                x.actionIndex = parameter?.actionIndex;
              });
              if (parameter.isUnderParameterId !== null) {
                underParameterIdsArray.push({
                  underParameterId: parameter.isUnderParameterId,
                  myParameter: parameter,
                  sectionId: section.id,
                  subSectionId: subSection.id,
                });
              }
              
            });
        });
      });
      setIsSetTemplete(false);
      setProductTemplate(product);
      setRelatedParameters(relatedParametersArray);
      setUnderParameterIds(underParameterIdsArray);
    }
  };

  // useEffect(() => {
  //   if (router?.query?.clientTypeId) {
  //     setClientTypeDefaultValue(
  //       clientTypesValue?.find(
  //         (item: any) => item?.id === router?.query?.clientTypeId
  //       )
  //     );
  //   }
  //   if (router?.query?.customerId) {
  //     if (renderOptions()?.find(
  //       (item: any) => item?.id === router?.query?.customerId
  //     )) {

  //       setClientDefaultValue(
  //         renderOptions()?.find(
  //           (item: any) => item?.id === router?.query?.customerId
  //         )
  //       );
  //     }
  //     else {
  //       setClientDefaultValue(
  //         clientListData?.find(
  //           (item: any) => item?.id === router?.query?.customerId
  //         )
  //       );
  //     }

  //   }
  // }, [
  //   clientTypesValue,
  //   clientDefaultValue,
  //   clientTypeDefaultValue,
  //   router,
  //   router?.query?.customerId,
  //   renderOptions,
  // ]);

  useEffect(() => {
    if (connectionId) {
      if (!currentCalculationSessionId) {
        setCurrentSignalRConnectionId(connectionId);
        setCurrentProductItemValueTotalPrice(null);
        setLastProductItemValueTotalPrice(null);
        setUrgentOrder(null);
        setWorkFlows([]);
        setJobActions([]);
        setSubProducts([]);
        setWorkTypes([]);
        setCalculationProgress({
          totalWorkFlowsCount: 0,
          currentWorkFlowsCount: 0,
        });
        setCurrentProductItemValue(null);
        setIsChangePriceForEdit(false);
        setIsJobNameValue(false);
        setIsActionEmployeeUpdated(false);

        // Set default values for your states
        setIsChargeForNewDie(false);
        setMissionsListForEdit([]);
        setSampleType(null);
        setIsRequiredParameters([]);
        setActiveSectionRequiredParameters([]);
        setdocumentItemByEdit({});
        setProductItemValueByEdit(null);
        setGalleryModalOpen(false);
        setMultiParameterModal(false);
        setMakeShapeOpen(false);
        setPrintingNotes("");
        setGraphicNotes("");
        setErrorMsg("");
        setProductTemplate([]);
        setupdatedProductTemplate(null);
        setDefaultProductTemplate([]);
        setSubProductsColorsModal([]);
        setIsSetTemplete(false);
        setCurrentProductItemValueDraftId("");
        setAdditionalProductItems([]);
        stIsFlowChanged(false);
        setCurrentProductItemValueTotalWorkFlows(0);
        setExpanded("");
        setActiveIndex(0);
        setActiveTab(t("products.offsetPrice.admin.logs"));
        setPricingDefaultValue(null);
        setCalculationExceptionsLogs(null);
        setQuantityTypes([]);
        // setCalculationResult(null);
        setOutSuppliers(null);
        setLoading(false);
        setLoadingWithLockScreen(false);
        setPriceRecovery(true);
        setCanCalculation(false);
        setSelectParameterButton(null);
        setDeviceCategory("");
        setDeviceSize("");
        setSaveCurrentStation(false);
        setcalculationServerErrorState(false);
        // setCurrentSignalRConnectionId(null);
        setCurrentCalculationSessionId("");
        setRequestAbortController(null);
        setBillingMethod(null);
        setGraphicDesigner(null);
        setIncludeVAT(null);
        if (
          widgetType === EWidgetProductType.EDIT ||
          widgetType === EWidgetProductType.DUPLICATE
        ) {
          getAllMaterial().then((materials) => {
            getProductQuoteItemById(materials);
          });
        } else {
          getAllMaterial().then((materials) => {
            getProductById(materials);
          });
        }
      }
    }
  }, [connectionId]);
  const exampleTypeValues = useRecoilValue(exampleTypeState);
  const billingMethodValues = useRecoilValue(billingMethodState);
  const listEmployeesValues = useRecoilValue(listEmployeesAtom);

  const getGraphicsTypeValue = (billingMethod?: GraphicsTypesParam) => {
    if (billingMethod && billingMethod === GraphicsTypesParam.PriceHour) {
      return billingMethodValues.find((item) => item.value === "workingHours");
    } else {
      return billingMethodValues.find((item) => item.value === "fixedPrice");
    }
  };
  const getSampleTypeValue = (sampleType?: SampleTypeParm) => {
    if (sampleType && sampleType === SampleTypeParm.Full) {
      return exampleTypeValues.find((item) => item.value === "Full");
    } else {
      return exampleTypeValues.find((item) => item.value === "PrintOnly");
    }
  };

  useEffect(() => {
    if (
      widgetType === EWidgetProductType.EDIT ||
      widgetType === EWidgetProductType.DUPLICATE
    ) {
      if (documentItemByEdit) {
        setGraphicNotes(documentItemByEdit.graphicNotes);
        setPrintingNotes(documentItemByEdit.printingNotes);
        if (documentItemByEdit?.exampleType) {
          setSampleType(getSampleTypeValue(documentItemByEdit?.exampleType));
        }
        if (documentItemByEdit?.graphicsTypes) {
          setBillingMethod(
            getGraphicsTypeValue(documentItemByEdit?.graphicsTypes)
          );
        }
        if (documentItemByEdit?.graphicsEmployeeId) {
          setGraphicDesigner(
            listEmployeesValues?.find(
              (item) => item.id === documentItemByEdit?.graphicsEmployeeId
            )
          );
        }
      }
    }
  }, [widgetType, documentItemByEdit]);

  useEffect(() => {
    if (canCalculation) {
      calculationProduct(subProducts);
    }
  }, [subProducts, canCalculation, productTemplate, router]);

  interface BillingMethod {
    value: string;
    text: string;
  }

  const getGraphicsType = (
    billingMethod?: BillingMethod
  ): GraphicsTypesParam => {
    if (billingMethod && billingMethod.value === "workingHours") {
      return GraphicsTypesParam.PriceHour;
    } else {
      return GraphicsTypesParam.PriceRegularHour;
    }
  };

  const getSampleType = (sampleType?: BillingMethod): SampleTypeParm => {
    if (sampleType && sampleType.value === "Full") {
      return SampleTypeParm.Full;
    } else {
      return SampleTypeParm.PrintOnly;
    }
  };

  const { duplicateType } = router?.query;
  const itemId = duplicateType
    ? Number(duplicateType) === DuplicateType.SameOrderAndSameBoardMissionNumber
      ? router?.query?.documentItemId
      : router?.query?.documentId
    : undefined;

  useEffect(() => {
    const itemQuantity = quantity ? +quantity?.values[0] : 1;
    if (currentProductItemValueTotalPrice && itemQuantity) {
      const productItemValue = {
        signalRConnectionId: connectionId,
        productItemValueId: productItemValueDraftId,
        itemId: itemId,
        productId: router?.query?.productId,
        // supplierId: graphicDesigner && graphicDesigner?.id,
        customerID: router?.query?.customerId,
        unitPrice: +currentProductItemValueTotalPrice / itemQuantity,
        finalPrice: +currentProductItemValueTotalPrice,
        amount: itemQuantity,
        isNeedGraphics: false,
        isUrgentWork: urgentOrder,
        printingNotes,
        graphicNotes,
        isNeedExample: false,
        isDuplicatedWithAnotherQuantity: false,
        graphicsEmployeeId: graphicDesigner?.id,
        graphicsTypes: billingMethod && getGraphicsType(billingMethod),
        duplicateType: router?.query?.duplicateType,
        documentId: router?.query?.documentId,
        exampleType: sampleType && getSampleType(sampleType),
      };
      setCurrentProductItemValue(productItemValue);
    }
  }, [
    subProducts,
    selectedWorkFlow,
    currentProductItemValueTotalPrice,
    graphicDesigner,
    connectionId,
    productItemValueDraftId,
    router,
    urgentOrder,
    printingNotes,
    graphicNotes,
    billingMethod,
    sampleType,
  ]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const _getParameter = (parameter: any, subSection: any, section: any) => {
    const allParameters = subProducts.flatMap((item) => item.parameters);
    let temp = [...allParameters];
    const index = temp?.findIndex(
      (item) =>
        item?.parameterId === parameter?.id &&
        item?.sectionId === section?.id &&
        item?.subSectionId === subSection?.id &&
        item?.actionIndex === parameter?.actionIndex
    );
    return temp[index];
  };

  useEffect(() => {
    if (subProducts) {
      let copySubProducts = cloneDeep(subProducts);
      const targetSubProduct = copySubProducts.find(
        (item) => item.type === null
      );

      if (targetSubProduct && targetSubProduct.parameters) {
        let temp = [...targetSubProduct.parameters];
        const sizesParametersArray = [];
        const hasDeviceSize = temp.some(
          (item) => item.parameterCode === "devicesize"
        );
        let widthParm = temp.find((item) => item.parameterCode === "Width");
        let heightParm = temp.find((item) => item.parameterCode === "Height");
        let sizeParm = temp.find((item) => item.parameterCode === "size");
        let devicecategoryParm = temp.find(
          (item) => item.parameterCode === "devicecategory"
        );

        if (!hasDeviceSize && sizeParm?.values[0] !== "custom") {
          sizesParametersArray.forEach((parameter) => {
            let dieCutSizeSubProductParameter = temp.find(
              (x) => x.parameterCode === parameter.parameterCode
            );
            if (dieCutSizeSubProductParameter) {
              dieCutSizeSubProductParameter.isDisabled = true;
            }
          });
        } else if (!hasDeviceSize && devicecategoryParm) {
          sizesParametersArray.push({
            parameterCode: "Width",
            value: widthParm,
          });
          sizesParametersArray.push({
            parameterCode: "Height",
            value: heightParm,
          });
          sizesParametersArray.push({ parameterCode: "size", value: "custom" });
          sizesParametersArray.forEach((parameter) => {
            let dieCutSizeSubProductParameter = temp.find(
              (x) => x.parameterCode === parameter.parameterCode
            );
            if (dieCutSizeSubProductParameter) {
              dieCutSizeSubProductParameter.isDisabled = false;
            }
          });

          let temp2 = cloneDeep(subProducts);
          const index2 = temp2.findIndex((item) => item.type === null);
          temp2[index2] = {
            type: null,
            parameters: temp,
          };

          // Only update state if there's a difference
          if (JSON.stringify(subProducts) !== JSON.stringify(temp2)) {
            setSubProducts(temp2);
          }
        }
      }
    }
  }, [subProducts, setSubProducts]);

  function findParameterInTemplate(template, parameterCodes) {
    let foundParameters = [];

    // Helper function to search within a given section or subsection
    function searchParameters(parameters, sectionId, subSectionId) {
      parameters.forEach((param) => {
        if (parameterCodes.includes(param.code)) {
          foundParameters.push({ ...param, sectionId, subSectionId });
        }
      });
    }

    // Iterate through sections and their subsections
    template.sections.forEach((section) => {
      section.subSections.forEach((subSection) => {
        searchParameters(subSection.parameters, section.id, subSection.id);
      });
    });

    return foundParameters;
  }
  const getMaterialCategories = async () => {
    let copySubProducts = cloneDeep(subProducts);
    // i need check the type when the device parameter not in the first section *** Important ***
    const targetSubProduct = copySubProducts.find((item) => item.type === null);
    let temp = [...targetSubProduct.parameters];
    const sizesParametersArray = [];

    const callBack = (res) => {
      if (res?.success) {
        let printedMaterialWidth = res?.data?.printedMaterialWidth;
        let printedMaterialLength = res?.data?.printedMaterialLength;
        if (printedMaterialWidth) {
          printedMaterialWidth = parseFloat(printedMaterialWidth).toFixed(2);
        }
        if (printedMaterialLength) {
          printedMaterialLength = parseFloat(printedMaterialLength).toFixed(2);
        }
        sizesParametersArray.push({
          parameterCode: "Width",
          value: printedMaterialWidth,
        });
        sizesParametersArray.push({
          parameterCode: "Height",
          value: printedMaterialLength,
        });

        // Clone the product template
        let templateCopy = cloneDeep(productTemplate);

        // Check if the template contains a parameter with code "size"
        const sizeParameterExists =
          findParameterInTemplate(templateCopy, ["size"]).length > 0;

        // Push 'custom' size code if the template has a size parameter
        if (sizeParameterExists) {
          sizesParametersArray.push({ parameterCode: "size", value: "custom" });
        }
        sizesParametersArray.forEach((parameter) => {
          let dieCutSizeSubProductParameter = temp.find(
            (x) => x.parameterCode === parameter.parameterCode
          );
          if (dieCutSizeSubProductParameter) {
            dieCutSizeSubProductParameter.values = [parameter.value];
            dieCutSizeSubProductParameter.isDisabled =
              res?.data?.printedMaterialWidth === null &&
                res?.data?.printedMaterialLength === null
                ? false
                : true;
          } else {
            const foundParameters = findParameterInTemplate(templateCopy, [
              "Width",
              "Height",
            ]);
            if (foundParameters?.length > 0) {
              foundParameters.forEach((myparameter) => {
                temp.push({
                  parameterId: myparameter.id,
                  sectionId: myparameter.sectionId,
                  subSectionId: myparameter.subSectionId,
                  ParameterType: myparameter.parameterType,
                  isQuantityParameter: myparameter.isQuantityParameter,
                  parameterName: myparameter.name,
                  actionId: myparameter.actionId,
                  values: [parameter.value],
                  valueIds: [],
                  actionIndex: myparameter.actionIndex,
                  parameterCode: myparameter.code,
                  valuesConfigs: myparameter.valuesConfigs,
                  unitKey: myparameter.unitKey,
                  unitType: myparameter.unitType,
                  isDisabled: true,
                  isGeneralInfoInProductionFloor:
                    myparameter.isGeneralInfoInProductionFloor,
                });
              });
            }
          }
        });

        let temp2 = [...subProducts];
        const index2 = temp2.findIndex((item) => item.type === null);
        temp2[index2] = {
          type: null,
          parameters: temp,
        };
        setSubProducts(temp2);
      }
    };
    await getDeviceSizeMockApi(callApi, callBack, {
      categoryKey: deviceCategory,
      name: deviceSize,
    });
  };
  useEffect(() => {
    if (deviceSize && deviceCategory) {
      getMaterialCategories();
    }
  }, [deviceSize, deviceCategory]);

  const setOpenQuantityComponent = useDebouncedCallback(() => {
    setOpenQuantityComponentModal(true);
  }, 500);

  const _renderParameterType = (
    parameter: any,
    subSection: any,
    section: any,
    subSectionParameters,
    value,
    list,
    inModal: any,
    inRow: boolean,
    inunderParameter: boolean
  ) => {
    let Comp;
    const parametersArray = subProducts.flatMap((item) => item.parameters);
    const temp = [...parametersArray];
    const index = temp?.findIndex(
      (item) =>
        item.parameterId === parameter?.id &&
        item.sectionId === section?.id &&
        item.subSectionId === subSection?.id &&
        item?.actionIndex === parameter?.actionIndex
    );
    if (
      parameter?.parameterType === EParameterTypes.INPUT_NUMBER &&
      parameter?.code === "quantity"
    ) {
      const typesNum =
        typesParam && typesParam.values ? typesParam.values[0] : 0;
      const isSets =
        isSetsParameter && isSetsParameter.values
          ? isSetsParameter.values[0]
          : "";
      const isInputDisabled = typesNum > 1 || isSets === "true";
      Comp = (
        <React.Fragment>
          <InputNumberParameterWidget
            clasess={clasess}
            parameter={parameter}
            index={index}
            temp={temp}
            onChangeSubProductsForPrice={onChangeSubProductsForPrice}
            subSection={subSection}
            section={section}
            type="number"
            disabled={isInputDisabled}
            widgetType={widgetType}
            setIsJobNameValue={setIsJobNameValue}
          />
          <QuantityParameter
            subSection={subSection}
            section={section}
            validateParameters={validateParameters}
            setCanCalculation={setCanCalculation}
          />
        </React.Fragment>
      );
    } else if (
      parameter?.parameterType === EParameterTypes.INPUT_NUMBER &&
      parameter?.id === typesParam?.parameterId
    ) {
      const isSets =
        isSetsParameter && isSetsParameter.values
          ? isSetsParameter.values[0]
          : "";
      const isInputDisabled = isSets === "true";
      Comp = (
        <React.Fragment>
          <InputNumberParameterWidget
            clasess={clasess}
            parameter={parameter}
            index={index}
            temp={temp}
            onChangeSubProductsForPrice={onChangeSubProductsForPrice}
            subSection={subSection}
            section={section}
            type="number"
            disabled={isInputDisabled}
            extraOnChange={() => {
              setOpenQuantityComponent();
            }}
            widgetType={widgetType}
            setIsJobNameValue={setIsJobNameValue}
          />
        </React.Fragment>
      );
    } else if (
      parameter?.parameterType === EParameterTypes.INPUT_NUMBER &&
      parameter?.id === widthParam?.parameterId
    ) {
      Comp = (
        <InputNumberParameterWidget
          clasess={clasess}
          parameter={parameter}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          type="number"
          widgetType={widgetType}
          setIsJobNameValue={setIsJobNameValue}
        />
      );
    } else if (
      parameter?.parameterType === EParameterTypes.INPUT_NUMBER &&
      parameter?.id === heightParam?.parameterId
    ) {
      Comp = (
        <InputNumberParameterWidget
          clasess={clasess}
          parameter={parameter}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          type="number"
          widgetType={widgetType}
          setIsJobNameValue={setIsJobNameValue}
        />
      );
    } else if (parameter?.parameterType === EParameterTypes.INPUT_NUMBER) {
      Comp = (
        <InputNumberParameterWidget
          clasess={clasess}
          parameter={parameter}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          type="number"
          widgetType={widgetType}
          setIsJobNameValue={setIsJobNameValue}
        />
      );
    } else if (parameter?.parameterType === EParameterTypes.INPUT_TEXT) {
      Comp = (
        <InputNumberParameterWidget
          clasess={clasess}
          parameter={parameter}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          type="text"
          widgetType={widgetType}
          setIsJobNameValue={setIsJobNameValue}
        />
      );
    } else if (parameter?.parameterType === EParameterTypes.DROP_DOWN_LIST || parameter?.parameterType === EParameterTypes.MACHINES_SELECT) {
      Comp = (
        <DropDownListParameterWidget
          parameter={parameter}
          clasess={clasess}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          selectedValueConfig={selectedValueConfig}
          inModal={inModal}
          setSelectedValueConfig={setSelectedValueConfig}
          onOpeneMultiParameterModal={onOpeneMultiParameterModal}
          subSectionParameters={subSectionParameters}
          list={list}
          multiParameterModal={multiParameterModal}
        />
      );
    } else if (
      parameter?.parameterType === EParameterTypes.SELECT_CHILDS_PARAMETERS
    ) {
      let disabled = false;
      const sizeParameter = getParameterByParameterCode(subProducts, "size");
      if (sizeParameter && sizeParameter.isDisabled) {
        disabled = true;
      }
      Comp = (
        <SelectChildParameterWidget
          parameter={parameter}
          clasess={clasess}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          disabled={disabled}
          multiParameterModal={multiParameterModal}
        />
      );
    } else if (parameter?.parameterType === EParameterTypes.SWITCH) {
      Comp = (
        <SWITCHParameterWidget
          parameter={parameter}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
        />
      );
    } else if (parameter?.parameterType === EParameterTypes.BUTTON) {
      Comp = (
        <ButtonParameterWidget
          clasess={clasess}
          parameter={parameter}
          selectBtnTypeToAction={selectBtnTypeToAction}
          subSection={subSection}
          section={section}
          index={index}
          straightKnife={straightKnife}
          allMaterials={allMaterials}
        />
      );
    } else if (parameter?.parameterType === EParameterTypes.SELECT_MATERIALS) {
      Comp = (
        <DropDownListParameterWidget
          parameter={parameter}
          clasess={clasess}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          selectedValueConfig={selectedValueConfig}
          inModal={inModal}
          setSelectedValueConfig={setSelectedValueConfig}
          onOpeneMultiParameterModal={onOpeneMultiParameterModal}
          subSectionParameters={subSectionParameters}
          list={list}
          setDeviceCategory={setDeviceCategory}
          setDeviceSize={setDeviceSize}
        />
      );
    } else if (
      parameter?.parameterType === EParameterTypes.ADVERTISING_PRODUCT_CATEGORY
    ) {
      Comp = (
        <AdvertisingProductCategoryParameterWidget
          parameter={parameter}
          clasess={clasess}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          selectedValueConfig={selectedValueConfig}
          inModal={inModal}
          setSelectedValueConfig={setSelectedValueConfig}
          onOpeneMultiParameterModal={onOpeneMultiParameterModal}
          subSectionParameters={subSectionParameters}
          list={list}
        />
      );
    } else if (
      parameter?.parameterType === EParameterTypes.ADVERTISING_PRODUCT_NAME
    ) {
      Comp = (
        <AdvertisingProductNameParameterWidget
          parameter={parameter}
          clasess={clasess}
          index={index}
          temp={temp}
          onChangeSubProductsForPrice={onChangeSubProductsForPrice}
          subSection={subSection}
          section={section}
          selectedValueConfig={selectedValueConfig}
          inModal={inModal}
          setSelectedValueConfig={setSelectedValueConfig}
          onOpeneMultiParameterModal={onOpeneMultiParameterModal}
          subSectionParameters={subSectionParameters}
          list={list}
        />
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        <div
          style={
            inRow ? clasess.parameterRowContainer : clasess.parameterContainer
          }
        >
          <div
            style={
              errorText && parameter?.isRequired && !value?.values?.[0]
                ? clasess.parameterRequierdLabelStyle
                : value?.values?.[0] === "true"
                  ? inunderParameter
                    ? clasess.parameterunderParameterStyle
                    : clasess.parameterType3ActiveLabelStyle
                  : inunderParameter
                    ? clasess.underParameterLabelStyle
                    : clasess.parameterLabelStyle
            }
          >
            {parameter?.name}
            <small>&nbsp;{parameter?.defaultUnit}</small>
            {parameter?.isRequired ? (
              <span style={clasess.spanRequierd}> *</span>
            ) : null}
          </div>
          <div
            style={
              inRow ? { width: "5%" } : clasess.renderParameterTypeContainer
            }
          >
            {Comp}
          </div>
          {!inunderParameter && (
            <div style={clasess.oldSystemValueText}>
              {parameter?.oldSystemValue}
            </div>
          )}
        </div>
        <>
          {parameter?.relatedParameters?.length > 0 &&
            inModal &&
            subSection.optionToDuplicateContent && (
              <>
                {parameter?.relatedParameters
                  .filter((relatedParameter) =>
                    subSection.parameters.some(
                      (p) => p.id === relatedParameter.parameterId
                    )
                  )
                  .filter(
                    (relatedParameter) =>
                      !underParameterIds.some(
                        (underParam) =>
                          underParam.myParameter?.id ===
                          relatedParameter.parameterId
                      )
                  )
                  .map((relatedParameter) => {
                    const subProduct = subProducts?.find(
                      (x) => x.type === subSection?.type
                    );
                    const parm = subProduct?.parameters?.find(
                      (param) =>
                        param.parameterId === parameter.id &&
                        param.actionIndex === parameter.actionIndex
                    );
                    const myParameter = list?.find(
                      (p) =>
                        p.id === relatedParameter.parameterId &&
                        p.actionIndex === relatedParameter.actionIndex
                    );

                    if (relatedParameter.activateByAllValues && parm?.values) {
                      if (relatedParameter.activateByAllValues) {
                        if (subSection.optionToDuplicateContent) {
                          const { parameterId, actionIndex } = relatedParameter;
                          const myParameter = list?.find(
                            (p) =>
                              p.id === parameterId &&
                              p.actionIndex === actionIndex
                          );
                          return (
                            <React.Fragment>
                              {relatedParameter.isStartNewLine ? <div style={{ flexBasis: '100%', height: 0 }} /> : <></>}
                              <div key={parameterId}>
                                {_renderParameterType(
                                  myParameter,
                                  subSection,
                                  section,
                                  subSection?.parameters,
                                  myParameter?.value,
                                  list,
                                  true,
                                  false,
                                  false
                                )}
                              </div>
                            </React.Fragment>

                          );
                        }
                      }
                      let productCopy = cloneDeep(productTemplate);
                      const sectionCopy = productCopy.sections?.find(
                        (x) => x.id === section.id
                      );
                      const subSectionCopy = sectionCopy.subSections?.find(
                        (x) => x.id === subSection.id
                      );
                      const param = subSectionCopy.parameters?.find(
                        (x) => x.id === relatedParameter.parameterId
                      );
                      if (param.isHidden == false) {
                        return;
                      }
                      param.isHidden = false;
                      setProductTemplate(productCopy);
                    } else if (
                      parameter?.parameterType ===
                      EParameterTypes.DROP_DOWN_LIST ||
                      parameter?.parameterType ===
                      EParameterTypes.SELECT_MATERIALS
                    ) {
                      const valueInArray =
                        relatedParameter.selectedValueIds?.find(
                          (c) => c == parm?.valueIds
                        );

                      if (valueInArray) {
                        if (subSection.optionToDuplicateContent) {
                          const { parameterId, actionIndex } = relatedParameter;
                          const myParameter = list?.find(
                            (p) =>
                              p.id === parameterId &&
                              p.actionIndex === actionIndex
                          );
                          return (
                            <React.Fragment>
                              {myParameter.isStartNewLine ? <div style={{ flexBasis: '100%', height: 0 }} /> : <></>}
                              <div key={parameterId}>
                                {_renderParameterType(
                                  myParameter,
                                  subSection,
                                  section,
                                  subSection?.parameters,
                                  myParameter?.value,
                                  list,
                                  true,
                                  false,
                                  false
                                )}
                              </div>
                            </React.Fragment>
                          );
                        }
                        if (relatedParameter.activateByAllValues) {
                          if (subSection.optionToDuplicateContent) {
                            const { parameterId, actionIndex } =
                              relatedParameter;
                            const myParameter = list?.find(
                              (p) =>
                                p.id === parameterId &&
                                p.actionIndex === actionIndex
                            );
                            return (
                              <React.Fragment>
                                {myParameter.isStartNewLine ? <div style={{ flexBasis: '100%', height: 0 }} /> : <></>}
                                <div key={parameterId}>
                                  {_renderParameterType(
                                    myParameter,
                                    subSection,
                                    section,
                                    subSection?.parameters,
                                    myParameter?.value,
                                    list,
                                    true,
                                    false,
                                    false
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          }

                          let productCopy = cloneDeep(productTemplate);
                          const sectionCopy = productCopy.sections?.find(
                            (x) => x.id === section.id
                          );
                          const subSectionCopy = sectionCopy.subSections?.find(
                            (x) => x.id === subSection.id
                          );
                          const param = subSectionCopy.parameters?.find(
                            (x) => x.id === relatedParameter.parameterId && x.actionIndex == relatedParameter.actionIndex
                          );
                          if (param.isHidden == false) {
                            return;
                          }
                          param.isHidden = false;
                          setProductTemplate(productCopy);
                        }

                        let productCopy = cloneDeep(productTemplate);
                        const sectionCopy = productCopy.sections?.find(
                          (x) => x.id === section.id
                        );
                        const subSectionCopy = sectionCopy.subSections?.find(
                          (x) => x.id === subSection.id
                        );
                        const param = subSectionCopy.parameters?.find(
                          (x) => x.id === relatedParameter.parameterId && x.actionIndex == relatedParameter.actionIndex
                        );
                        if (param.isHidden == false) {
                          return;
                        }
                        param.isHidden = false;
                        setProductTemplate(productCopy);
                      }

                      if (
                        relatedParameter.activateByAllValues &&
                        parm?.values
                      ) {
                        let productCopy = cloneDeep(productTemplate);
                        const sectionCopy = productCopy.sections?.find(
                          (x) => x.id === section.id
                        );
                        const subSectionCopy = sectionCopy.subSections?.find(
                          (x) => x.id === subSection.id
                        );
                        const param = subSectionCopy.parameters?.find(
                          (x) => x.id === relatedParameter.parameterId && x.actionIndex == relatedParameter.actionIndex
                        );
                        if (param.isHidden == false) {
                          return;
                        }
                        param.isHidden = false;
                        setProductTemplate(productCopy);
                      } else {
                        let productCopy = cloneDeep(productTemplate);
                        const sectionCopy = productCopy.sections.find(
                          (x) => x.id === section.id
                        );
                        const subSectionCopy = sectionCopy.subSections.find(
                          (x) => x.id === subSection.id
                        );
                        const param = subSectionCopy.parameters.find(
                          (x) => x.id === relatedParameter.parameterId && x.actionIndex == relatedParameter.actionIndex
                        );
                        if (param && param.isHidden == true) {
                          return;
                        }
                        if(param){
                          param.isHidden = true;
                          setProductTemplate(productCopy);
                        }
                        
                       
                      }
                    } else {
                      const valueInArray =
                        relatedParameter.selectedValueIds?.find(
                          (c) => c == parm?.values
                        );
                      if (
                        (valueInArray && myParameter) ||
                        (!parm &&
                          relatedParameter &&
                          relatedParameter.selectedValueIds &&
                          relatedParameter.selectedValueIds.length > 0 &&
                          relatedParameter.selectedValueIds[0] === "false")
                      ) {
                        let productCopy = cloneDeep(productTemplate);
                        const sectionCopy = productCopy.sections?.find(
                          (x) => x.id === section.id
                        );
                        const subSectionCopy = sectionCopy.subSections?.find(
                          (x) => x.id === subSection.id
                        );
                        const param = subSectionCopy.parameters?.find(
                          (x) => x.id === relatedParameter.parameterId
                        );

                        if (param.isHidden == false) {
                          return;
                        }
                        param.isHidden = false;
                        setProductTemplate(productCopy);
                      } else {
                        let productCopy = cloneDeep(productTemplate);
                        const sectionCopy = productCopy.sections.find(
                          (x) => x.id === section.id
                        );
                        const subSectionCopy = sectionCopy.subSections.find(
                          (x) => x.id === subSection.id
                        );
                        const param = subSectionCopy.parameters.find(
                          (x) => x.id === relatedParameter.parameterId
                        );
                        if (param.isHidden == true) {
                          return;
                        }
                        param.isHidden = true;
                        setProductTemplate(productCopy);
                      }
                    }
                  })}
              </>
            )}
        </>
      </div>
    );
  };
  const processRelatedParameters2 = (
    parameter,
    subSection,
    section,
    productTemplate,
    subProducts
  ) => {
    if (subSection.optionToDuplicateContent) {
      return;
    }
    const relatedParametersfilterd = parameter?.relatedParameters?.filter(
      (relatedParameter) =>
        subSection.parameters.some((p) => p.id === relatedParameter.parameterId)
    );
    if(parameter.code === "Puncturetype"){
      debugger;
    }
    relatedParametersfilterd?.map((relatedParameter) => {
      const subProduct = subProducts?.find((x) => x.type === subSection?.type);
      let actionIndex = parameter.actionIndex;
      if (actionIndex == null) {
        actionIndex = 0;
      }

      const parm = subProduct?.parameters?.find(
        (param) =>
          param.parameterId === parameter.id &&
          param.actionIndex === parameter.actionIndex
      );
      const myParameter = subSection?.parameters?.find(
        (p) =>
          p.id === relatedParameter.parameterId && p.actionIndex === actionIndex
      );
      if (!myParameter) {
        return;
      }
      if (parameter.isHidden == true) {
        const sectionCopy = productTemplate.sections.find(
          (x) => x.id === section.id
        );
        const subSectionCopy = sectionCopy.subSections.find(
          (x) => x.id === subSection.id
        );
        const param = subSectionCopy.parameters.find(
          (x) => x.id === relatedParameter.parameterId
        );
        if (param.isHidden == true) {
          return;
        }

        subProducts.forEach((x) => {
          if (x.type == subSection.type) {
            x = {
              ...x,
              parameters: x.parameters.filter((p) => p.parameterId != param.id),
            };
          }
        });
        param.isHidden = true;
        processRelatedParameters2(
          param,
          subSection,
          section,
          productTemplate,
          subProducts
        );
        return;
      }

      let paramValue = "";
      if (parm?.values && parm?.values.length > 0) {
        paramValue = parm?.values[0];
      }
      if (relatedParameter.activateByAllValues && paramValue) {
        const sectionCopy = productTemplate.sections?.find(
          (x) => x.id === section.id
        );
        const subSectionCopy = sectionCopy.subSections?.find(
          (x) => x.id === subSection.id
        );
        const param = subSectionCopy.parameters?.find(
          (x) => x.id === relatedParameter.parameterId
        );
        if (param.isHidden == false) {
          return;
        }
        param.isHidden = false;
        processRelatedParameters2(
          param,
          subSection,
          section,
          productTemplate,
          subProducts
        );
      } 
      else if (relatedParameter.activateByAllValues && !paramValue) {
        const sectionCopy = productTemplate.sections.find(
          (x) => x.id === section.id
        );
        const subSectionCopy = sectionCopy.subSections.find(
          (x) => x.id === subSection.id
        );
        const param = subSectionCopy.parameters.find(
          (x) => x.id === relatedParameter.parameterId
        );
        if (param.isHidden == true) {
          return;
        }
        param.isHidden = true;
        // subProducts.forEach(x => {
        //   if (x.type == subSection.type) {
        //     x.parameters = x.parameters.filter(p => p.parameterId != param.id)
        //   }
        // })
        subProducts.forEach((x) => {
          if (x.type == subSection.type) {
            x = {
              ...x,
              parameters: x.parameters.filter((p) => p.parameterId != param.id),
            };
          }
        });
        processRelatedParameters2(
          param,
          subSection,
          section,
          productTemplate,
          subProducts
        );
      } else if (
        parameter?.parameterType === EParameterTypes.DROP_DOWN_LIST ||
        parameter?.parameterType === EParameterTypes.SELECT_MATERIALS
      ) {
        const valueInArray = relatedParameter.selectedValueIds?.find(
          (c) => c == parm?.valueIds
        );

        if (valueInArray) {
          const sectionCopy = productTemplate.sections?.find(
            (x) => x.id === section.id
          );
          const subSectionCopy = sectionCopy.subSections?.find(
            (x) => x.id === subSection.id
          );
          const param = subSectionCopy.parameters?.find(
            (x) => x.id === relatedParameter.parameterId
          );
          if (param.isHidden == false) {
            return;
          }
          param.isHidden = false;
          processRelatedParameters2(
            param,
            subSection,
            section,
            productTemplate,
            subProducts
          );
        } else {
          const sectionCopy = productTemplate.sections.find(
            (x) => x.id === section.id
          );
          const subSectionCopy = sectionCopy.subSections.find(
            (x) => x.id === subSection.id
          );
          const param = subSectionCopy.parameters.find(
            (x) => x.id === relatedParameter.parameterId
          );
          if (param.isHidden == true) {
            return;
          }
          subProducts.forEach((x) => {
            if (x.type == subSection.type) {
              x = {
                ...x,
                parameters: x.parameters.filter(
                  (p) => p.parameterId != param.id
                ),
              };
            }
          });
          param.isHidden = true;
          processRelatedParameters2(
            param,
            subSection,
            section,
            productTemplate,
            subProducts
          );
        }
      } else {
        const valueInArray = relatedParameter.selectedValueIds?.find(
          (c) => c == parm?.values || (c == "false" && !parm?.values)
        );
        if (
          (valueInArray && myParameter) ||
          (!parm &&
            relatedParameter &&
            relatedParameter.selectedValueIds &&
            relatedParameter.selectedValueIds.length > 0 &&
            relatedParameter.selectedValueIds[0] === "false")
        ) {
          const sectionCopy = productTemplate.sections?.find(
            (x) => x.id === section.id
          );
          const subSectionCopy = sectionCopy.subSections?.find(
            (x) => x.id === subSection.id
          );
          const param = subSectionCopy.parameters?.find(
            (x) => x.id === relatedParameter.parameterId
          );
          if (param.isHidden == false) {
            processRelatedParameters2(
                param,
                subSection,
                section,
                productTemplate,
                subProducts
            );
            return;
          }
          param.isHidden = false;
        } else {
          const sectionCopy = productTemplate.sections.find(
            (x) => x.id === section.id
          );
          const subSectionCopy = sectionCopy.subSections.find(
            (x) => x.id === subSection.id
          );
          const param = subSectionCopy.parameters.find(
            (x) => x.id === relatedParameter.parameterId
          );
          if (param.isHidden == true) {
            processRelatedParameters2(
                param,
                subSection,
                section,
                productTemplate,
                subProducts
            );
            return;
          }
          subProducts.forEach((x) => {
            if (x.type == subSection.type) {
              x = {
                ...x,
                parameters: x.parameters.filter(
                  (p) => p.parameterId != param.id
                ),
              };
            }
          });
          param.isHidden = true;
          processRelatedParameters2(
            param,
            subSection,
            section,
            productTemplate,
            subProducts
          );
        }
      }
    });
  };

  const [valuesState, setValuesState] = useRecoilState(
    tempProductQuantityTypesValuesState
  );

  const removeRelatedParametersFromSubProducts = (
    parameterId: string,
    sectionId: string,
    subSectionId: string,
    parameters: any[],
    actionIndex: number,
  ) => {
    // Find the parameter in the productTemplate
    const productTemplateCopy = cloneDeep(productTemplate);
    const section = productTemplateCopy.sections.find(
      (section) => section.id === sectionId
    );
    const subSection = section?.subSections.find(
      (sub) => sub.id === subSectionId
    );
    const deletedParameter = subSection?.parameters.find(
      (param) => param.id === parameterId && param.actionIndex == actionIndex
    );

    // Check if the deleted parameter has related parameters
    if (deletedParameter && deletedParameter.relatedParameters) {
      deletedParameter.relatedParameters.forEach((relatedParam) => {
        // Remove the related parameter from the parameters array
        const relatedIndex = parameters.findIndex(
          (param) => param.parameterId === relatedParam.parameterId && param.actionIndex == relatedParam.actionIndex
        );

        if (relatedIndex !== -1) {
          const relatedParameterId = parameters[relatedIndex].parameterId;

          // Remove the related parameter
          parameters.splice(relatedIndex, 1);

          // Recursively remove related parameters for the removed parameter
          removeRelatedParametersFromSubProducts(
            relatedParameterId,
            sectionId,
            subSectionId,
            parameters,
            deletedParameter.actionIndex
          );
        }
      });
    }
  };
  const addRelatedParametersWithDefaultValues = (
    parameterId: string,
    sectionId: string,
    subSectionId: string,
    parameters: any[],
    productTemplateCopy
  ) => {
    // Find the parameter in the productTemplate
    //const productTemplateCopy = cloneDeep();
    const section = productTemplateCopy.sections.find(
      (section) => section.id === sectionId
    );
    const subSection = section?.subSections.find(
      (sub) => sub.id === subSectionId
    );
    const parentParameter = subSection?.parameters.find(
      (param) => param.id === parameterId
    );
    if(parentParameter.code === "Puncturetype"){
      debugger;
    }
    // Check if the parameter has related parameters
    if (parentParameter && parentParameter.relatedParameters) {
      parentParameter.relatedParameters.forEach((relatedParam) => {
        // Find the related parameter in the subSection
        const relatedParameter = subSection?.parameters.find(
          (param) => param.id === relatedParam.parameterId
        );
    
        if (relatedParameter) {
          
          let valueToAdd = null;
          let valueIds = null;

          // Handle the case where parameterType is 5
          if (
            relatedParameter.parameterType === 5 ||
            relatedParameter.parameterType === 0
          ) {
            const defaultConfig = relatedParameter.valuesConfigs?.find(
              (config) => config.isDefault
            );
            if (
              defaultConfig &&
              defaultConfig.values &&
              defaultConfig.values.length > 0
            ) {
              valueToAdd = defaultConfig.values[0];
              valueIds = defaultConfig.values[0];
            }else if(defaultConfig && defaultConfig.updateName ) {
              valueToAdd = defaultConfig.updateName;
              valueIds = defaultConfig.id;
            }
            else if (
              relatedParameter.valuesConfigs &&
              relatedParameter.valuesConfigs.length === 1 &&
              relatedParameter.valuesConfigs[0]?.values?.length > 0 &&
              relatedParameter.isRequired
            ) {
              valueToAdd = relatedParameter.valuesConfigs[0]?.updateName;
              valueIds = relatedParameter.valuesConfigs[0]?.values[0];
            }
          } else if (relatedParameter.defaultValue) {
            valueToAdd = relatedParameter.defaultValue;
            valueIds = relatedParameter.defaultValue;
          }

          if (valueToAdd !== null) {
            // Check if the related parameter is already in the parameters array
            const relatedIndex = parameters.findIndex(
              (param) => param.parameterId === relatedParameter.id
            );
            
            if (relatedIndex === -1) {
              // Add the related parameter to the parameters array
              parameters.push({
                parameterId: relatedParameter.id,
                sectionId: sectionId,
                subSectionId: subSectionId,
                ParameterType: relatedParameter.parameterType,
                isQuantityParameter: relatedParameter.isQuantityParameter,
                parameterName: relatedParameter.name,
                actionId: relatedParameter.actionId,
                values: [valueToAdd],
                valueIds: [valueIds], // Adjust if necessary
                actionIndex: relatedParameter.actionIndex,
                parameterCode: relatedParameter.code,
                valuesConfigs: relatedParameter?.valuesConfigs,
                unitKey: relatedParameter?.unitKey,
                unitType: relatedParameter?.unitType,
                isGeneralInfoInProductionFloor:
                  relatedParameter?.isGeneralInfoInProductionFloor,
              });
              relatedParameter.isHidden = false;
              const parameterValue = relatedParameter.valuesConfigs.find(
                  (x) => x.id === valueIds
              );
              let actionIndex = relatedParameter.actionIndex
              if (
                  relatedParameter.materialPath &&
                  relatedParameter.materialPath.length > 0
              ) {
                const materialPath =
                    relatedParameter.materialPath[
                    relatedParameter.materialPath.length - 1
                        ];
                const materialRelatedParameters = subSection.parameters.filter(
                    (x) =>
                        x.id !== relatedParameter.id &&
                        x.actionIndex === actionIndex &&
                        x.materialPath?.find((y) => compareStrings(y, materialPath))
                );
                if (materialRelatedParameters?.length > 0) {
                  parameters = parameters.filter((subProduct) => {
                    if (subProduct.actionIndex !== actionIndex) {
                      return true
                    }
                    return !materialRelatedParameters.some(
                        (materialParameter) =>
                            materialParameter.id === subProduct.parameterId
                    );
                  });
                }

                materialRelatedParameters?.forEach((param) => {
                  if (param.materialPath && param.materialPath.length > 0) {
                    const materialPath =
                        param.materialPath[param.materialPath.length - 1];
                    const index = param.materialPath.findIndex((x) =>
                        compareStrings(x, materialPath)
                    );
                    let allMaterialsCopy = cloneDeep(allMaterials);
                    let paramMaterialValues = [];
                    if (index > 0) {
                      allMaterialsCopy = allMaterialsCopy?.find((x) =>
                          compareStrings(x.pathName, param.materialPath[0])
                      )?.data;
                      for (let i = 0; i <= index; i++) {
                        const prevPath = param.materialPath[i];
                        const prevPathParam = subSection.parameters.find(
                            (x) =>
                                x.materialPath &&
                                x.materialPath.length > i &&
                                x.materialPath[i] === prevPath &&
                                x.actionIndex === actionIndex
                        );
                        let prevPathParamDefaultValue = parameters.find(
                            (x) => x.parameterId === prevPathParam.id && x.actionIndex === actionIndex
                        );

                        if (
                            prevPathParamDefaultValue &&
                            prevPathParamDefaultValue?.valueIds &&
                            prevPathParamDefaultValue?.valueIds.length
                        ) {
                          allMaterialsCopy = allMaterialsCopy?.find(
                              (x) =>
                                  x.valueId === prevPathParamDefaultValue?.valueIds[0]
                          )?.data;
                        } else {
                          // allMaterialsCopy = []
                        }
                      }
                      paramMaterialValues = allMaterialsCopy;
                    } else if (index === 0) {
                      allMaterialsCopy = allMaterialsCopy.find((x) =>
                          compareStrings(x.pathName, materialPath)
                      )?.data;
                      paramMaterialValues = allMaterialsCopy?.find((x) =>
                          parameterValue?.values?.find((y) => y === x.valueId)
                      )?.data;
                    }
                    param.valuesConfigs = [];
                    param.isHidden = false;
                    paramMaterialValues?.forEach((val) => {
                      param.valuesConfigs.push({
                        id: val.valueId,
                        activateAction: false,
                        isDefault: false,
                        isDeleted: false,
                        isHidden: false,
                        materialValueIds: null,
                        selectedParameterValues: null,
                        updateName: val.value,
                        values: [val.valueId],
                        additionalAttribute: val?.additionalAttribute,
                        currency: val?.currency,
                      });
                    });
                    param.valuesConfigs = param.valuesConfigs.filter(
                        (x) => x.values && x.values.length > 0
                    );
                    if (
                        param?.valuesConfigs &&
                        param?.valuesConfigs?.length === 1 &&
                        param?.isRequired
                    ) {
                      debugger
                      param.valuesConfigs[0].isDefault = true;
                      parameters.push({
                        parameterId: param?.id,
                        parameterName: param?.name,
                        actionId: param?.actionId,
                        parameterType: param?.parameterType,
                        isQuantityParameter: param?.isQuantityParameter,
                        valueIds: param?.valuesConfigs[0]?.values,
                        values: [param?.valuesConfigs[0]?.updateName],
                        sectionId: section?.id,
                        subSectionId: subSection?.id,
                        actionIndex: param?.actionIndex,
                        parameterCode: param?.code,
                        valuesConfigs: param?.valuesConfigs,
                        unitKey: param?.unitKey,
                        unitType: param?.unitType,
                        isGeneralInfoInProductionFloor:
                        param?.isGeneralInfoInProductionFloor,
                      });
                    }
                  }
                });
              }
              // Recursively add related parameters for the newly added parameter
              addRelatedParametersWithDefaultValues(
                relatedParameter.id,
                sectionId,
                subSectionId,
                parameters,
                productTemplateCopy
              );
            }
            
          }
        }
      });
    }
  };

  const hasDieCutOrDieKissCut = checkIfDieCutOrDieKissCutExists(subProducts);

  const onChangeSubProductsForPrice = (
    parameterId: any,
    subSectionId: any,
    sectionId: any,
    ParameterType: any,
    parameterName: any,
    actionId: any,
    data: any,
    subSectionType: any,
    index: number,
    actionIndex: number,
    parameterCode: string,
    dieCut: any
  ) => {
    let copySubProducts = cloneDeep(subProducts);
    const targetSubProduct = copySubProducts.find(
      (item) => item.type === subSectionType
    );
    if (targetSubProduct) {
      let temp = [...targetSubProduct.parameters];

      const findIndex = temp.findIndex(
        (item) =>
          item.parameterId === parameterId &&
          item.sectionId === sectionId &&
          item.subSectionId === subSectionId &&
          item.actionIndex === actionIndex
      );

      const productTemplateCopy = cloneDeep(productTemplate);
      const section = productTemplateCopy.sections.find(
        (section) => section.id === sectionId
      );
      const subSection = section.subSections.find(
        (sub) => sub.id === subSectionId
      );
      const subSectionParameter = subSection.parameters.find(
        (param) => param.id === parameterId && param.actionIndex === actionIndex
      );
      if (subSectionParameter) {
        subSectionParameter.isHidden = false;
      }
      if (findIndex !== -1) {
        const valuesArray = [data.values].filter(Boolean);
        temp[findIndex] = {
          ...temp[findIndex],
          values: [data.values],
          valueIds: [data.valueIds],
        };
        if (valuesArray.length > 0 && valuesArray[0] !== "false") {
          temp[findIndex] = {
            ...temp[findIndex],
            values: valuesArray,
            valueIds: [data.valueIds].filter(Boolean), // Remove null and undefined
          };
          addRelatedParametersWithDefaultValues(
              parameterId,
              sectionId,
              subSectionId,
              temp,
              productTemplateCopy
          );
        } else {
          temp.splice(findIndex, 1);
          removeRelatedParametersFromSubProducts(
            parameterId,
            sectionId,
            subSectionId,
            temp,
            subSectionParameter.actionIndex,
          );
        }
      } else {
        temp.push({
          parameterId: parameterId,
          sectionId: sectionId,
          subSectionId: subSectionId,
          ParameterType: ParameterType,
          isQuantityParameter: subSectionParameter?.isQuantityParameter,
          parameterName: parameterName,
          actionId: actionId,
          values: [data.values],
          valueIds: [data.valueIds],
          actionIndex,
          parameterCode,
          valuesConfigs: subSectionParameter?.valuesConfigs,
          unitKey: subSectionParameter?.unitKey,
          unitType: subSectionParameter?.unitType,
          isGeneralInfoInProductionFloor:
            subSectionParameter?.isGeneralInfoInProductionFloor,
        });
        addRelatedParametersWithDefaultValues(
          parameterId,
          sectionId,
          subSectionId,
          temp,
            productTemplateCopy
        );
      }
      if (subSectionParameter) {
        if (subSectionParameter?.childsParameters) {
          subSectionParameter?.childsParameters.forEach((myparameter) => {
            const parameterId = myparameter.id;
            const myindex = temp.findIndex((item) => {
              return (
                item?.parameterId === myparameter?.id &&
                item?.sectionId === section?.id &&
                item?.subSectionId === subSection?.id &&
                item?.actionIndex === myparameter?.actionIndex
              );
            });
            if (data?.value === null && data?.values === undefined) {
              subSectionParameter.childsParameters?.forEach((childParam) => {
                temp = temp.filter(
                  (item) => item.parameterId !== childParam.id
                );
              });
            }
            if (data?.value?.values) {
              if (myindex !== -1) {
                if (data?.value?.values[parameterId] !== undefined) {
                  temp[myindex] = {
                    ...temp[myindex],
                    parameterCode: myparameter?.code,
                    values: [data?.value?.values[parameterId]],
                    isDisabled: hasValues(data?.value),
                  };
                } else {
                  temp.splice(myindex, 1);
                }
              } else {
                temp.push({
                  parameterId: myparameter?.id,
                  sectionId: section?.id,
                  subSectionId: subSection?.id,
                  ParameterType: myparameter?.parameterType,
                  isQuantityParameter: myparameter?.isQuantityParameter,
                  values: [data?.value?.values[parameterId]],
                  actionIndex: myparameter?.actionIndex,
                  parameterName: myparameter?.name,
                  parameterCode: myparameter?.code,
                  isDisabled: hasValues(data?.value),
                  isGeneralInfoInProductionFloor:
                    myparameter?.isGeneralInfoInProductionFloor,
                });
              }
            }
          });
        }
        if (subSectionParameter?.code === "Shape" && hasDieCutOrDieKissCut) {
          const parametersToRemove = [
            "Height",
            "Width",
            "size",
            "DieCut",
            "DieKissCut",
            "DieStamp",
          ];

          temp = temp.filter(
            (param) => !parametersToRemove.includes(param.parameterCode)
          );
        }
        //types parameter
        if (subSectionParameter.id == "de2bb7d5-01b1-4b2b-b0fa-81cd0445841b") {
          const typesNum = Number(data.values);
          const quantityValue =
            quantity && quantity.values ? quantity?.values[0] : 0;
          const workName =
            jobNameParameter && jobNameParameter.values
              ? jobNameParameter.values[0]
              : "";
          if (Number(typesNum) === 0 || Number(typesNum) === 1) {
            setQuantityTypes([]);
          } else {
            if (quantityTypes.length === Number(typesNum)) {
              setValuesState(quantityTypes);
              setQuantityTypes(quantityTypes);
            } else if (quantityTypes.length < typesNum) {
              const array = [];
              for (let i = quantityTypes.length + 1; i <= typesNum; i++) {
                array.push({
                  name: workName + " " + i,
                  quantity: valuesState[0]?.quantity || Number(quantityValue),
                });

                setValuesState([...quantityTypes, ...array]);
                setQuantityTypes([...quantityTypes, ...array]);
              }
            } else if (quantityTypes.length > typesNum) {
              setValuesState(quantityTypes.slice(0, typesNum));
              setQuantityTypes(quantityTypes.slice(0, typesNum));
            }
          }
        }

        if (subSectionParameter.id == "0fdbca1a-f250-447b-93e3-5b91909da59c") {
          if (!quantity) {
            temp.push({
              parameterId: "4991945c-5e07-4773-8f11-2e3483b70b53",
              sectionId: sectionId,
              subSectionId: subSectionId,
              ParameterType: 1,
              parameterName: "Quantity",
              actionId: null,
              values: ["0"],
              valueIds: [null],
              actionIndex: 0,
              parameterCode: "quantity",
              valuesConfigs: [],
              unitKey: null,
              unitType: 0,
            });
          }
          //setQuantity
          const setUnits = setUnitsParameter ? setUnitsParameter.values[0] : 0;
          temp = temp.map((x) =>
            x.parameterId === quantity?.parameterId
              ? { ...x, values: [(setUnits * data.values).toString()] }
              : x
          );
        }
        if (subSectionParameter.id == "91d3fe77-b852-4974-beb6-2da7d7616c78") {
          if (!quantity) {
            temp.push({
              parameterId: "4991945c-5e07-4773-8f11-2e3483b70b53",
              sectionId: sectionId,
              subSectionId: subSectionId,
              ParameterType: 1,
              parameterName: "Quantity",
              actionId: null,
              values: ["0"],
              valueIds: [null],
              actionIndex: 0,
              parameterCode: "quantity",
              valuesConfigs: [],
              unitKey: null,
              unitType: 0,
            });
          }
          // SetUnits
          const setQuantity = setQuantityParameter
            ? setQuantityParameter.values[0]
            : 0;
          temp = temp.map((x) =>
            x.parameterId === quantity?.parameterId
              ? { ...x, values: [(data.values * setQuantity).toString()] }
              : x
          );
        }
        if (
          subSectionParameter.settingParameters &&
          subSectionParameter.settingParameters.length > 0
        ) {
          subSectionParameter.settingParameters.forEach((settingParam) => {
            temp = temp.filter((x) => x.parameterId != settingParam.id);
          });
        }
        const parameterValue = subSectionParameter.valuesConfigs.find(
          (x) => x.id === data.valueIds
        );
        if (
          subSectionParameter.materialPath &&
          subSectionParameter.materialPath.length > 0
        ) {
          const materialPath =
            subSectionParameter.materialPath[
            subSectionParameter.materialPath.length - 1
            ];
          const materialRelatedParameters = subSection.parameters.filter(
            (x) =>
              x.id !== subSectionParameter.id &&
              x.actionIndex === actionIndex &&
              x.materialPath?.find((y) => compareStrings(y, materialPath))
          );
          if (materialRelatedParameters?.length > 0) {
            temp = temp.filter((subProduct) => {
              if (subProduct.actionIndex !== actionIndex) {
                return true
              }
              return !materialRelatedParameters.some(
                (materialParameter) =>
                  materialParameter.id === subProduct.parameterId
              );
            });
          }

          materialRelatedParameters?.forEach((param) => {
            if (param.materialPath && param.materialPath.length > 0) {
              const materialPath =
                param.materialPath[param.materialPath.length - 1];
              const index = param.materialPath.findIndex((x) =>
                compareStrings(x, materialPath)
              );
              let allMaterialsCopy = cloneDeep(allMaterials);
              let paramMaterialValues = [];
              if (index > 0) {
                allMaterialsCopy = allMaterialsCopy?.find((x) =>
                  compareStrings(x.pathName, param.materialPath[0])
                )?.data;
                for (let i = 0; i <= index; i++) {
                  const prevPath = param.materialPath[i];
                  const prevPathParam = subSection.parameters.find(
                    (x) =>
                      x.materialPath &&
                      x.materialPath.length > i &&
                      x.materialPath[i] === prevPath &&
                      x.actionIndex === actionIndex
                  );
                  let prevPathParamDefaultValue = temp.find(
                    (x) => x.parameterId === prevPathParam.id && x.actionIndex === actionIndex
                  );

                  if (
                    prevPathParamDefaultValue &&
                    prevPathParamDefaultValue?.valueIds &&
                    prevPathParamDefaultValue?.valueIds.length
                  ) {
                    allMaterialsCopy = allMaterialsCopy?.find(
                      (x) =>
                        x.valueId === prevPathParamDefaultValue?.valueIds[0]
                    )?.data;
                  } else {
                    // allMaterialsCopy = []
                  }
                }
                paramMaterialValues = allMaterialsCopy;
              } else if (index === 0) {
                allMaterialsCopy = allMaterialsCopy.find((x) =>
                  compareStrings(x.pathName, materialPath)
                )?.data;
                paramMaterialValues = allMaterialsCopy?.find((x) =>
                  parameterValue?.values?.find((y) => y === x.valueId)
                )?.data;
              }
              param.valuesConfigs = [];
              //param.isHidden = false;
              paramMaterialValues?.forEach((val) => {
                param.valuesConfigs.push({
                  id: val.valueId,
                  activateAction: false,
                  isDefault: false,
                  isDeleted: false,
                  isHidden: false,
                  materialValueIds: null,
                  selectedParameterValues: null,
                  updateName: val.value,
                  values: [val.valueId],
                  additionalAttribute: val?.additionalAttribute,
                  currency: val?.currency,
                });
              });
              param.valuesConfigs = param.valuesConfigs.filter(
                (x) => x.values && x.values.length > 0
              );
              if (
                param?.valuesConfigs &&
                param?.valuesConfigs?.length === 1 &&
                param?.isRequired
              ) {
                param.valuesConfigs[0].isDefault = true;
                temp.push({
                  parameterId: param?.id,
                  parameterName: param?.name,
                  actionId: param?.actionId,
                  parameterType: param?.parameterType,
                  isQuantityParameter: param?.isQuantityParameter,
                  valueIds: param?.valuesConfigs[0]?.values,
                  values: [param?.valuesConfigs[0]?.updateName],
                  sectionId: section?.id,
                  subSectionId: subSection?.id,
                  actionIndex: param?.actionIndex,
                  parameterCode: param?.code,
                  valuesConfigs: param?.valuesConfigs,
                  unitKey: param?.unitKey,
                  unitType: param?.unitType,
                  isGeneralInfoInProductionFloor:
                    param?.isGeneralInfoInProductionFloor,
                });
              }
            }
          });
        }
        setProductTemplate(productTemplateCopy);
        if (
          parameterValue &&
          parameterValue.selectedParameterValues &&
          parameterValue.selectedParameterValues.length > 0
        ) {
          parameterValue.selectedParameterValues.forEach((selectedParam) => {
            if (selectedParam.valueIds && selectedParam.valueIds.length > 0) {
              const param = subSectionParameter.settingParameters.find(
                (param) => param.id === selectedParam.parameterId
              );
              temp.push({
                parameterId: param.id,
                sectionId: sectionId,
                subSectionId: subSectionId,
                ParameterType: param.parameterType,
                isQuantityParameter: param?.isQuantityParameter,
                parameterName: param.name,
                actionId: param.actionId,
                values: selectedParam.valueIds,
                valueIds: selectedParam.valueIds,
                valuesConfigs: selectedParam?.valuesConfigs,
                unitKey: param?.unitKey,
                unitType: param?.unitType,
                actionIndex,
                parameterCode: param?.code,
                isGeneralInfoInProductionFloor:
                  param?.isGeneralInfoInProductionFloor,
              });
            }
          });
        }

        if (dieCut) {
          if (subSectionParameter.code === "DieCut") {
            let dieUnitWidth = dieCut.rowData.dieUnitWidth.value + "";
            let dieUnitLength = dieCut.rowData.dieUnitLength.value + "";
            let finalUnitWidth = dieCut.rowData.finalUnitWidth.value + "";
            let finalUnitLength = dieCut.rowData.finalUnitLength.value + "";
            let finalUnitHeight = dieCut.rowData.finalUnitHeight.value + "";
            const dieCutSizesParametersArray = [];
            dieCutSizesParametersArray.push({
              parameterCode: "Width",
              value: dieUnitWidth,
            });
            dieCutSizesParametersArray.push({
              parameterCode: "Height",
              value: dieUnitLength,
            });
            dieCutSizesParametersArray.push({
              parameterCode: "DieUnitWidth",
              value: finalUnitWidth,
            });
            dieCutSizesParametersArray.push({
              parameterCode: "DieUnitLength",
              value: finalUnitLength,
            });
            dieCutSizesParametersArray.push({
              parameterCode: "DieUnitHeight",
              value: finalUnitHeight,
            });
            dieCutSizesParametersArray.forEach((dieCutSizeParameter) => {
              let dieCutSizeSubProductParameter = temp.find(
                (x) => x.parameterCode == dieCutSizeParameter.parameterCode
              );
              if (dieCutSizeSubProductParameter) {
                dieCutSizeSubProductParameter.values = [
                  dieCutSizeParameter.value,
                ];
                dieCutSizeSubProductParameter.isDisabled = true;
              } else {
                const subSectionParameter = subSection.parameters.find(
                  (param) => param.code === dieCutSizeParameter.parameterCode
                );
                if (subSectionParameter) {
                  temp.push({
                    parameterId: subSectionParameter.id,
                    sectionId: sectionId,
                    subSectionId: subSectionId,
                    ParameterType: ParameterType,
                    isQuantityParameter:
                      subSectionParameter?.isQuantityParameter,
                    parameterName: subSectionParameter?.name,
                    actionId: actionId,
                    values: [dieCutSizeParameter.value],
                    valueIds: [],
                    actionIndex: subSectionParameter.actionIndex,
                    parameterCode: subSectionParameter.code,
                    valuesConfigs: subSectionParameter?.valuesConfigs,
                    unitKey: subSectionParameter?.unitKey,
                    unitType: subSectionParameter?.unitType,
                    isDisabled: true,
                    isGeneralInfoInProductionFloor:
                      subSectionParameter?.isGeneralInfoInProductionFloor,
                  });
                }
              }
            });
          } else if (subSectionParameter.code === "DieKissCut") {
            let unitWidth = dieCut?.rowData?.unitWidth?.value + "";
            let unitLength = dieCut?.rowData?.unitLength?.value + "";
            const dieCutSizesParametersArray = [];
            dieCutSizesParametersArray.push({
              parameterCode: "Width",
              value: unitWidth,
            });
            dieCutSizesParametersArray.push({
              parameterCode: "Height",
              value: unitLength,
            });
            dieCutSizesParametersArray.forEach((dieCutSizeParameter) => {
              let dieCutSizeSubProductParameter = temp.find(
                (x) => x.parameterCode == dieCutSizeParameter.parameterCode
              );
              if (dieCutSizeSubProductParameter) {
                dieCutSizeSubProductParameter.values = [
                  dieCutSizeParameter.value,
                ];
                dieCutSizeSubProductParameter.isDisabled = true;
              } else {
                const subSectionParameter = subSection.parameters.find(
                  (param) => param.code === dieCutSizeParameter.parameterCode
                );
                temp.push({
                  parameterId: subSectionParameter.id,
                  sectionId: sectionId,
                  subSectionId: subSectionId,
                  ParameterType: ParameterType,
                  isQuantityParameter: subSectionParameter?.isQuantityParameter,
                  parameterName: subSectionParameter?.name,
                  actionId: actionId,
                  values: [dieCutSizeParameter.value],
                  valueIds: [],
                  actionIndex: subSectionParameter.actionIndex,
                  parameterCode: subSectionParameter.code,
                  valuesConfigs: subSectionParameter?.valuesConfigs,
                  unitKey: subSectionParameter?.unitKey,
                  unitType: subSectionParameter?.unitType,
                  isDisabled: true,
                  isGeneralInfoInProductionFloor:
                    subSectionParameter?.isGeneralInfoInProductionFloor,
                });
              }
            });
          } else if (subSectionParameter.code === "DieStamp") {
            let unitWidth = dieCut?.rowData?.width?.value + "";
            let unitLength = dieCut?.rowData?.length?.value + "";
            const dieCutSizesParametersArray = [];
            dieCutSizesParametersArray.push({
              parameterCode: "EnhancementWidth",
              value: unitWidth,
              actionIndex: subSectionParameter?.actionIndex,
            });
            dieCutSizesParametersArray.push({
              parameterCode: "EnhancementLength",
              value: unitLength,
              actionIndex: subSectionParameter?.actionIndex,
            });
            dieCutSizesParametersArray.forEach((dieCutSizeParameter) => {
              let dieCutSizeSubProductParameter = temp.find(
                (x) =>
                  x.parameterCode == dieCutSizeParameter.parameterCode &&
                  x?.actionIndex === dieCutSizeParameter?.actionIndex
              );
              if (dieCutSizeSubProductParameter) {
                dieCutSizeSubProductParameter.values = [
                  dieCutSizeParameter.value,
                ];
                dieCutSizeSubProductParameter.isDisabled = true;
              } else {
                const subSectionParameter = subSection.parameters.find(
                  (param) =>
                    param.code === dieCutSizeParameter.parameterCode &&
                    param?.actionIndex === dieCutSizeParameter?.actionIndex
                );
                temp.push({
                  parameterId: subSectionParameter.id,
                  sectionId: sectionId,
                  subSectionId: subSectionId,
                  ParameterType: ParameterType,
                  isQuantityParameter: subSectionParameter?.isQuantityParameter,
                  parameterName: subSectionParameter?.name,
                  actionId: actionId,
                  values: [dieCutSizeParameter.value],
                  valueIds: [],
                  actionIndex: subSectionParameter.actionIndex,
                  parameterCode: subSectionParameter.code,
                  valuesConfigs: subSectionParameter?.valuesConfigs,
                  unitKey: subSectionParameter?.unitKey,
                  unitType: subSectionParameter?.unitType,
                  isDisabled: true,
                  isGeneralInfoInProductionFloor:
                    subSectionParameter?.isGeneralInfoInProductionFloor,
                });
              }
            });
          }

          let sizeParameter = subSection.parameters.find(
            (param) => param.code === "size"
          );
          if (sizeParameter) {
            let sizeSubProductParameter = temp.find(
              (x) => x.parameterCode == "size"
            );
            let customValue = sizeParameter.valuesConfigs.find(
              (x) => !x.values || Object.keys(x.values).length === 0
            );
            if (customValue) {
              if (sizeSubProductParameter) {
                sizeSubProductParameter.valueIds = [customValue.id];
                sizeSubProductParameter.values = [customValue.updateName];
                sizeSubProductParameter.isDisabled = true;
              } else {
                temp.push({
                  parameterId: sizeParameter.id,
                  sectionId: sectionId,
                  subSectionId: subSectionId,
                  ParameterType: sizeParameter.parameterType,
                  isQuantityParameter: sizeParameter?.isQuantityParameter,
                  parameterName: sizeParameter.name,
                  actionId: sizeParameter.actionId,
                  values: [customValue.updateName],
                  valueIds: [customValue.id],
                  actionIndex: sizeParameter.actionIndex,
                  parameterCode: sizeParameter.code,
                  valuesConfigs: sizeParameter?.valuesConfigs,
                  unitKey: sizeParameter?.unitKey,
                  unitType: sizeParameter?.unitType,
                  isDisabled: true,
                  isGeneralInfoInProductionFloor:
                    sizeParameter?.isGeneralInfoInProductionFloor,
                });
              }
            }
          }
        }
        
      }
      
      let temp2 = [...subProducts];
      const index2 = temp2.findIndex((item) => item.type === subSectionType);
      temp2[index2] = {
        type: subSectionType,
        sectionId: sectionId,
        sectionName: section.name,
        parameters: temp,
      };
      // const updatedSubProducts = removeHiddenParametersFromSubProducts(temp2, productTemplateCopy);
      setSubProducts(temp2);
      setSubProductsColorsModal(temp2);
      processRelatedParameters2(
        subSectionParameter,
        subSection,
        section,
        productTemplateCopy,
        temp2
      );
      setProductTemplate(productTemplateCopy);
      const updatedProductTemplate = updateIsHidden(productTemplateCopy, temp2);
      removeHiddenParameters(temp2, productTemplateCopy);
      setupdatedProductTemplate(updatedProductTemplate);
      setCanCalculation(true);
    }
  };
  const onCloseMakeShape = () => {
    setMakeShapeOpen(false);
  };
  const onCloseGalleryModal = () => {
    setGalleryModalOpen(false);
  };
  const onOpeneMakeShape = () => {
    setMakeShapeOpen(true);
  };
  const onOpeneGalleryModal = () => {
    setGalleryModalOpen(true);
  };
  const [settingParameters, setSettingParameters] = useState({});
  const onOpeneMultiParameterModal = (
    paameters,
    subSection,
    section,
    subSectionParameters,
    list
  ) => {
    setMultiParameterModal(true);
    const value = _getParameter(paameters, subSection, section);
    setSettingParameters({
      parameter: paameters,
      subSection: subSection,
      section: section,
      subSectionParameters: subSectionParameters,
      value: value,
      list: list,
    });
  };
  const onCloseMultiParameterModal = () => {
    setMultiParameterModal(false);
  };
  const [errorText, setErrorText] = useState(false);
  const handleTabClick = (index: number) => {
    if (!firstEnter) {
      setActiveIndex(index);
    }
    // Allow moving to any previous tab regardless of checkParameter
    if (index < activeIndex) {
      setActiveIndex(index);
      setCanCalculation(false);
    } else if (index > activeIndex) {
      let currentSectionId = productTemplate.sections[activeIndex].id;
      let checkParameter = validateParameters(subProducts, currentSectionId);
      // Move to the next tab only if checkParameter is true
      if (checkParameter) {
        setErrorText(false);
        setCanCalculation(false);
        setActiveIndex(index);
        setCanCalculation(false);
      } else {
        // Show error if trying to move to the next tab but checkParameter is false
        alertFault("products.offsetPrice.admin.errorReq");
        setErrorText(true);
      }
    }
    // No action needed if clicking on the current tab
  };
  const handleNextClick = () => {
    setErrorText(false);
    let currentSection = productTemplate.sections[activeIndex].id;
    let checkParameter = validateParameters(subProducts, currentSection);
    if (checkParameter) {
      if (activeIndex < productTemplate.sections.length) {
        setActiveIndex(activeIndex + 1);
        setCanCalculation(false);
      }
    } else {
      setErrorText(true);
      alertFault("products.offsetPrice.admin.errorReq");
    }
  };
  const handlePreviousClick = () => {
    if (activeIndex != 0) {
      setActiveIndex(activeIndex - 1);
      setCanCalculation(false);
    }
  };

  const onClickProductionTab = () => {
    setActiveTab(t("quality.production"));
    setBillingMethod(null);
    setGraphicDesigner(null);
  };
  const onClickDetailsTab = () => {
    setActiveTab(t("reports.details"));
  };

  const onClickLogsTab = () => {
    setActiveTab(t("products.offsetPrice.admin.logs"));
    setBillingMethod(null);
    setGraphicDesigner(null);
  };

  const isShowDetailsTab = productTemplate?.isShowDetailsTab;
  const tabs = [
    {
      name: t("products.offsetPrice.admin.logs"),
      onclick: () => onClickLogsTab,
    },
    ...(isShowDetailsTab
      ? [
        {
          name: t("reports.details"),
          onclick: () => onClickDetailsTab,
        },
      ]
      : []),
    {
      name: t("quality.production"),
      onclick: () => onClickProductionTab,
      showIndicator: printingNotes !== "" || sampleType,
    },
  ];

  const getProductById = async (materials) => {
    if (router?.query?.isFromChatbot) {
      await getAndSetChatBotProductId(
        callApi,
        (data) => {
          const updatedTemplate = updateIsHidden(data, subProducts);
          setDefaultProductTemplate(updatedTemplate);
          initProduct(updatedTemplate, materials);
          let checkParameter = validateParameters(subProducts);
          if (checkParameter) {
            setCanCalculation(true);
          } else {
            setCanCalculation(false);
          }
        },
        {
          Id: router?.query?.productId,
        }
      );
    } else {
      await getAndSetProductById(
        callApi,
        (data) => {
          const updatedTemplate = updateIsHidden(data, subProducts);
          setDefaultProductTemplate(updatedTemplate);
          initProduct(updatedTemplate, materials);
          let checkParameter = validateParameters(subProducts);
          if (checkParameter) {
            setCanCalculation(true);
          } else {
            setCanCalculation(false);
          }
        },
        {
          Id: router?.query?.productId,
          sourceView: ESourceView.ProductCalculationView
        }
      );
    }
  };
  const getProductQuoteItemById = async (materials) => {
    if (connectionId) {
      const callBack = (res) => {
        if (res?.success) {
          const updatedTemplate = updateIsHidden(res?.data, subProducts);
          setDefaultProductTemplate(updatedTemplate);
          initQuoteItemProduct(updatedTemplate, materials);
          setdocumentItemByEdit(res?.data.docmentItem);
          setCalculationResult({ ...calculationResult, tabs: res?.data?.tabs });
          setProductItemValueByEdit(res?.data.productItemValue);
          setValuesState(res?.data?.productItemValue?.workTypes);
          setWorkTypes(res?.data?.productItemValue?.workTypes);
          setMissionsListForEdit(res?.data?.missions);
        } else {
          alertFaultUpdate();
        }
      };
      await getProductByItemIdApi(callApi, callBack, {
        documentItemId: router?.query?.documentItemId,
        signalRConnectionId: connectionId,
        documentType: router?.query?.documentType,
      });
    }
  };

  const initQuoteItemProduct = (quoteItemProduct, materials) => {
    if (
      quoteItemProduct &&
      quoteItemProduct.productItemValue &&
      quoteItemProduct.productItemValue.itemParmetersValues
    ) {
      const quoteItemSubProducts = [];
      setCurrentProductItemValueTotalPrice(
        quoteItemProduct.docmentItem.finalPrice
      );
      setLastProductItemValueTotalPrice(
        quoteItemProduct.docmentItem.finalPrice
      );
      setCurrentProductItemValue(quoteItemProduct.productItemValue);
      setCurrentProductItemValueDraftId(
        quoteItemProduct.productItemValueDraftId
      );
      setUrgentOrder(quoteItemProduct.docmentItem.isUrgentWork);
      setCurrentCalculationSessionId(quoteItemProduct.productItemValueDraftId);
      setWorkFlows(quoteItemProduct.productItemValue.workFlows);
      setJobActions(quoteItemProduct.productItemValue.actions);
      setCalculationProgress({
        totalWorkFlowsCount: 0,
        currentWorkFlowsCount: 0,
      });
    }
    initProduct(quoteItemProduct, materials);
  };

  const validateParameters = (currentSubProducts, sectionId?: string) => {
    let isValid = true;
    const allParameters = currentSubProducts.flatMap((item) => item.parameters);
    if (sectionId) {
      let section = productTemplate.sections.find((x) => x.id === sectionId);
      for (const subSection of section.subSections) {
        for (const parameter of subSection.parameters) {
          if (!parameter.isHidden && parameter.isRequired) {
            const index = allParameters.findIndex(
              (par) =>
                par.parameterId === parameter.id &&
                par?.sectionId === section?.id &&
                par?.subSectionId === subSection?.id &&
                ((par?.values && par?.values[0]?.length) ||
                  (par?.valueIds && par?.valueIds[0]?.length))
            );
            if (index == -1) {
              isValid = false;
              break;
            }
          }
        }
      }
    } else {
      if (!productTemplate?.sections) {
        return true;
      }
      for (const section of productTemplate?.sections) {
        for (const subSection of section.subSections) {
          for (const parameter of subSection.parameters) {
            if (!parameter.isHidden && parameter.isRequired) {
              const index = allParameters.findIndex(
                (par) =>
                  par.parameterId === parameter.id &&
                  par?.sectionId === section?.id &&
                  par?.subSectionId === subSection?.id &&
                  ((par?.values && par?.values[0]?.length) ||
                    (par?.valueIds && par?.valueIds[0]?.length))
              );
              if (index == -1) {
                isValid = false;
                break;
              }
            }
          }
        }
      }
    }
    return isValid;
  };
  //const [checkParameter, setCheckParameter] = useRecoilState<boolean>(checkParameterState)
  /*useEffect(() => {
    let checkParameter = validateParameters(activeSectionRequiredParameters, subProducts);
    setCheckParameter(checkParameter)
  }, [isRequiredParameters])*/
  const getItemParameterValues = () => {
    const updatedSubProducts = removeHiddenParametersFromSubProducts(
      subProducts,
      productTemplate
    );

    const result = [];
    updatedSubProducts.forEach((subProduct) => {
      subProduct?.parameters?.forEach((param) => {
        const paramCopy = cloneDeep(param);
        paramCopy.subProductType = subProduct.type;
        result.push(paramCopy);
      });
    });

    // Filter non-empty parameter values or valueIds
    const filteredArray = result.filter(
      (obj) =>
        (obj.values && obj.values.length && obj.values[0] !== "false") ||
        (obj.valueIds && obj.valueIds.length)
    );

    // Use a Map to remove duplicates based on actionIndex, parameterCode, parameterId, sectionId, subSectionId
    const uniqueParamsMap = new Map();

    const uniqueFilteredArray = filteredArray.filter((param) => {
      const key = `${param.actionIndex}_${param.parameterCode}_${param.parameterId}_${param.sectionId}_${param.subSectionId}`;

      if (!uniqueParamsMap.has(key)) {
        uniqueParamsMap.set(key, true);
        return true; // Keep the first occurrence
      }

      return false; // Discard duplicates
    });

    return uniqueFilteredArray;
  };
  const calculationProduct = useDebouncedCallback(
    async (currentSubProducts) => {
      if (requestAbortController) {
        requestAbortController.abort();
      }
      setWorkFlows([]);
      setCurrentProductItemValueTotalPrice(null);
      setJobActions([]);
      setIsCalculationFinished(false);
      setCalculationProgress({
        totalWorkFlowsCount: 0,
        currentWorkFlowsCount: 0,
      });

      setCalculationExceptionsLogs([]);
      setAdditionalProductItems([]);
      setcalculationServerErrorState(false);

      let checkParameter = validateParameters(currentSubProducts);
      if (!!checkParameter) {
        const newRequestAbortController = new AbortController();
        setRequestAbortController(newRequestAbortController);
        let subProductsCopy = cloneDeep(currentSubProducts);
        let generalParameters = subProductsCopy.find(
          (x) => !x.type
        )?.parameters;
        let calculationSubProducts = subProductsCopy.filter((x) => x.type);
        generalParameters?.forEach((x) => (x.valuesConfigs = null));
        calculationSubProducts.forEach((x) =>
          x.parameters.forEach((y) => (y.valuesConfigs = null))
        );

        if (productTypesNumber === 1) {
          setWorkTypes([]);
        } else if (
          quantityTypes &&
          quantityTypes.length > 0 &&
          quantityTypes[0].quantity > 0
        ) {
          setWorkTypes(quantityTypes);
        }
        if (generalParameters && generalParameters?.length > 0) {
          if (!connectionId) {
            setcalculationServerErrorState(true);
            return;
          }
          setLoading(true);
          setErrorMsg("");
          const newCalculationSessionId = uuidv4();
          setCurrentCalculationSessionId(newCalculationSessionId);
          const itemParmetersValues = getItemParameterValues();
          const updatedGeneralParameters =
            removeHiddenParametersFromSubProductsParameters(
              generalParameters,
              productTemplate
            );
          const res: any = await callApi(
            "POST",
            `/v1/calculation-service/calculations/calculate-productV2`,
            {
              calculationSessionId: newCalculationSessionId,
              signalRConnectionId: connectionId,
              clientId: router?.query?.customerId,
              clientTypeId: router?.query?.clientTypeId,
              productId: router?.query?.productId,
              generalParameters: updatedGeneralParameters,
              subProducts: calculationSubProducts,
              itemParmetersValues: itemParmetersValues,
              workTypes: workTypes,
              isChargeForNewDie: isChargeForNewDie,
              productType: productTemplate?.productType,
              templateId: productTemplate?.templateId,
            },
            false,
            newRequestAbortController
          );

          if (res?.status === 500) {
            setCalculationProgress({
              totalWorkFlowsCount: 0,
              currentWorkFlowsCount: 0,
            });
            setLoading(false);
            setcalculationServerErrorState(true);
          } else if (!res.success) {
            setCalculationProgress({
              totalWorkFlowsCount: 0,
              currentWorkFlowsCount: 0,
            });
            //setLoading(false);
          } else {
            const id = res?.data?.data?.data;
            if (!id) {
              setcalculationServerErrorState(true);
              setLoading(false);
            }
            setCurrentCalculationSessionId(id);
            setCalculationProgress({
              totalWorkFlowsCount: 0,
              currentWorkFlowsCount: 0,
            });
            //setLoading(true);
            if (id === "00000000-0000-0000-0000-000000000000") {
              setLoading(false);
            }
          }
        }
      } else {
        setLoading(false);
        setCurrentCalculationSessionId(null);
      }
    },
    700
  );

  const getOutSourcingSuppliers = () => {
    const callBack = (res) => {
      if (res.success) {
        setOutSuppliers(res.data);
      }
    };
    getOutsourcingSuppliersListApi(callApi, callBack, {
      // clientId: router?.query?.customerId,
      // clientTypeId: router?.query?.clientTypeId,
      // productId: router?.query?.productId,
      // generalParameters: generalParameters,
      // subProducts: subProducts,
    }).then();
  };
  const PricingTab = {
    id: "c66465de-95d6-4ea3-bd3f-7efe60f4cb0555",
    name: t("products.offsetPrice.admin.Pricing"),
    icon: "pricing",
    jobDetails: pricingDefaultValue?.jobDetails,
    actions: pricingDefaultValue?.actions,
    flows: pricingDefaultValue?.workFlows,
  };
  const createProfitTestCase = useCallback(async () => {
    const generalParameters = [];
    const itemParmetersValues = getItemParameterValues();
    const res = await callApi(
      "POST",
      `/v1/printhouse-config/profits/create-profit-test-case?systemID=2`,
      {
        clientId: router?.query?.customerId,
        clientTypeId: router?.query?.clientTypeId,
        generalParameters: generalParameters,
        productItemDTO: {
          productId: router?.query?.productId,
          //details: pricingDefaultValue?.jobDetails,
          itemParmetersValues: itemParmetersValues,
          workFlow: selectedWorkFlow,
        },
        actionId: router?.query?.actionId,
        actionProductId: router?.query?.actionProductId,
      },
      false
    );
    if (res?.success) {
      navigate(`/products/profits?actionId=${router?.query?.actionId}`);
    }
  }, [router, pricingDefaultValue, selectedWorkFlow]);
  const quantity = useMemo(() => {
    if (subProducts) {
      const generalParameters = subProducts.find((x) => !x.type)?.parameters;
      return generalParameters?.find(
        (item) => item?.parameterId === "4991945c-5e07-4773-8f11-2e3483b70b53"
      );
    }
  }, [subProducts]);
  const setQuantityParameter = useMemo(() => {
    if (subProducts) {
      const generalParameters = subProducts.find((x) => !x.type)?.parameters;
      return generalParameters?.find(
        (item) => item?.parameterId === "0fdbca1a-f250-447b-93e3-5b91909da59c"
      );
    }
  }, [subProducts]);
  const setUnitsParameter = useMemo(() => {
    if (subProducts) {
      const generalParameters = subProducts.find((x) => !x.type)?.parameters;
      return generalParameters?.find(
        (item) => item?.parameterId === "91d3fe77-b852-4974-beb6-2da7d7616c78"
      );
    }
  }, [subProducts]);
  const typesParam = useMemo(() => {
    if (subProducts) {
      const generalParameters = subProducts.find((x) => !x.type)?.parameters;
      return generalParameters?.find(
        (item) => item?.parameterId === "de2bb7d5-01b1-4b2b-b0fa-81cd0445841b"
      );
    }
  }, [subProducts]);

  const widthParam = getParameterByParameterCode(subProducts, "Width");
  const heightParam = getParameterByParameterCode(subProducts, "Height");
  const jobNameParameter = getParameterByParameterCode(subProducts, "JobName");
  const isSetsParameter = getParameterByParameterCode(subProducts, "Sets");

  const addItemForQuotes = async () => {
    setLoadingWithLockScreen(true);
    const docType = router?.query?.documentType ?? "0";
    const duplicateType = router?.query?.duplicateType;
    const callBack = (res) => {
      if (res?.success) {
        docType === "0" || duplicateType === "1" || duplicateType === "2"
          ? navigate("/quote")
          : navigate(`/order?Id=${router?.query?.documentId}`);
        setLoadingWithLockScreen(false);
      } else {
        alertFaultAdded();
        setLoadingWithLockScreen(false);
      }
    };
    await addItemApi(callApi, callBack, {
      item: currentProductItemValue,
      documentType: docType,
    });
  };

  const updateQuoteItem = async (isSaveCurrentStation?) => {
    setLoadingWithLockScreen(true);

    // First, validate the workflows
    const workflowValidation = validateWorkflows(workFlows[0]);

    if (workflowValidation.isValid) {
      // If workflows are valid, proceed with the update
      const callBack = (res) => {
        if (res?.success) {
          router?.query?.documentType == "0"
            ? navigate("/quote")
            : navigate(`/order?Id=${router?.query?.documentId}`);
          setWorkFlows([]);
          setJobActions([]);
          setLoadingWithLockScreen(false);
        } else {
          alertFaultUpdate();
          setLoadingWithLockScreen(false);
        }
      };
      await updateDocumentItemApi(callApi, callBack, {
        Item: {
          signalRConnectionId: connectionId,
          productItemValueId: productItemValueDraftId,
          itemId: router?.query?.documentItemId,
          productId: router?.query?.productId,
          supplierId: "",
          customerID: router?.query?.customerId,
          unitPrice: +currentProductItemValueTotalPrice / +quantity?.values[0],
          finalPrice: +currentProductItemValueTotalPrice,
          amount: quantity?.values[0],
          isNeedGraphics: false,
          isUrgentWork: urgentOrder,
          printingNotes,
          graphicNotes,
          exampleType: sampleType && getSampleType(sampleType),
          isNeedExample: false,
          isDuplicatedWithAnotherQuantity: false,
          saveCurrentStation: isFlowChanged ? isSaveCurrentStation : true,
        },
        DocumentType: router?.query?.documentType,
      });
    } else {
      // If workflows are not valid, set error message and stop the process
      setLoadingWithLockScreen(false);
      // You can combine all error messages into a single string if needed
      setErrorMsg(workflowValidation.errors.join(", "));
    }
  };
  const straightKnife = findParameterByCode(productTemplate, "IsStraightKnife");

  // const navigateForRouter = () => {
  //   let checkParameter = validateParameters(subProducts);
  //   if (!!checkParameter) {
  //     setErrorMsg("");
  //     if (router?.query?.actionId) {
  //       createProfitTestCase();
  //     } else {
  //       addItemForQuotes();
  //     }
  //   } else {
  //     setErrorMsg(t("products.offsetPrice.admin.errorReq"));
  //   }
  // };
  const navigateForRouter = () => {
    // First, validate the workflows
    const workflowValidation = validateWorkflows(workFlows[0]);

    if (workflowValidation.isValid) {
      // If workflows are valid, proceed to validate parameters
      let checkParameter = validateParameters(subProducts);

      if (checkParameter) {
        setErrorMsg("");
        if (router?.query?.actionId) {
          createProfitTestCase();
        } else {
          addItemForQuotes();
        }
      } else {
        setErrorMsg(t("products.offsetPrice.admin.errorReq"));
      }
    } else {
      // If workflows are not valid, set error message
      // You can combine all error messages into a single string if needed
      setErrorMsg(workflowValidation.errors.join(", "));
    }
  };

  useEffect(() => {
    setPricingDefaultValue({
      actions: jobActions,
      workFlows,
      jobDetails,
    });
  }, [workFlows, jobActions, jobDetails]);
  const setCurrenciesSymbols = useSetRecoilState(currenciesSymbols);
  const getCurrenciesApi = async () => {
    const callBack = (res) => {
      if (res.success) {
        setCurrenciesSymbols(
          res.data.map(({ value, text }) => ({ label: text, value }))
        );
      }
    };
    await getCurrenciesSymbols(callApi, callBack);
  };
  useEffect(() => {
    getCurrenciesApi();
  }, []);
  useEffect(() => {
    const updatedProductTemplate = updateIsHidden(productTemplate, subProducts);
    setProductTemplate(updatedProductTemplate);
    
  }, [subProducts]);

  const updateIsHidden = (productTemplate, subProducts) => {
    if (
      !productTemplate ||
      !productTemplate.sections ||
      !Array.isArray(productTemplate.sections)
    ) {
      return productTemplate;
    }
    const allParameters = subProducts.flatMap((product) => product.parameters);
    const updatedTemplate = { ...productTemplate };
    updatedTemplate.sections.forEach((section) => {
      if (
        section.relatedToParameters &&
        section.relatedToParameters.length === 0
      ) {
        section.isHidden = false;
      } else if (section.relatedToParameters) {
        // let isHidden = true;
        section.relatedToParameters.forEach((parameter) => {
          if(parameter.actionIndex === undefined || parameter.actionIndex === null ){
            parameter.actionIndex = 0
          }
          // Check if any parameter in allParameters matches the condition
          const matchingParameter = allParameters.find(
            (p) =>
              p.parameterId === parameter.parameterId &&
              p.sectionId === parameter.sectionId &&
              p.subSectionId === parameter.subSectionId &&
              p.actionIndex === parameter.actionIndex
          );
          if (matchingParameter) {
            if (parameter.activateByAllValues) {
              section.isHidden = false;
            } else {
              section.isHidden = !parameter.selectedValueIds.includes(
                matchingParameter.valueIds[0]
              );
            }
          } else {
            section.isHidden = true;
          }
        });
      }
    });
    return updatedTemplate;
  };

  function removeHiddenParameters(subProducts, updatedProductTemplate) {
    const temp = cloneDeep(updatedProductTemplate);
    if (!temp || !temp.sections || !Array.isArray(temp.sections)) {
      return subProducts;
    }
    const hiddenSectionIds = temp.sections
      .filter((section) => section.isHidden)
      .map((section) => section.id);

    const updatedSubProducts = subProducts.map((product) => {
      const updatedParameters = product.parameters.filter(
        (parameter) => !hiddenSectionIds.includes(parameter.sectionId)
      );
      return {
        ...product,
        parameters: updatedParameters,
      };
    });

    setSubProducts(updatedSubProducts);
  }

  function removeHiddenParametersFromSubProducts(subProducts, productTemplate) {
    return subProducts.map((subProduct) => {
      const filteredParameters = subProduct?.parameters?.filter((param) => {
        
        let matchingTemplateParam = null;
        const section = productTemplate.sections.find(x => x.id == param.sectionId);
        if (section && section.subSections) {
          const subSection = section.subSections.find(x => x.id == param.subSectionId);
          if (subSection && subSection.parameters) {
            matchingTemplateParam = subSection.parameters.find(templateParam => templateParam.id === param.parameterId && templateParam.actionIndex === param.actionIndex && !templateParam?.isHiddenInSetting)
          }
        }
        // Check if the parameter is not hidden (isHidden = false)
        return matchingTemplateParam ? !matchingTemplateParam.isHidden : true;
      });

      // Return the updated subProduct with filtered parameters
      return { ...subProduct, parameters: filteredParameters };
    });
  }
  function removeHiddenParametersFromSubProductsParameters(
    parameters,
    productTemplate
  ) {
    return parameters.filter((param) => {
      let matchingTemplateParam = null;
      const section = productTemplate.sections.find(x => x.id == param.sectionId);
      if (section && section.subSections) {
        const subSection = section.subSections.find(x => x.id == param.subSectionId);
        if (subSection && subSection.parameters) {
          matchingTemplateParam = subSection.parameters.find(templateParam => templateParam.id === param.parameterId && templateParam.actionIndex === param.actionIndex && !templateParam?.isHiddenInSetting)
        }
      }
      // Check if the parameter is not hidden (isHidden = false)
      return matchingTemplateParam ? !matchingTemplateParam.isHidden : true;
    });
  }
  useEffect(() => {
    if (productTemplate?.length > 0) {
      const updateSections = () => {
        const updatedSections = productTemplate?.sections?.map((section) => {
          if (section?.relatedToParameters?.length === 0) {
            return { ...section, isHidden: false };
          } else {
            const hasHidden = section.relatedToParameters.some((rp) => {
              const subParam = subProducts.find((sub) =>
                sub.parameters.some(
                  (param) => param.parameterId === rp.parameterId
                )
              );
              if (subParam) {
                if (rp.activateByAllValues) {
                  return subParam.parameters?.every(
                    (param) => param.parameterId === rp.parameterId
                  );
                } else {
                  return subParam.parameters.some(
                    (param) =>
                      param.parameterId === rp.parameterId &&
                      rp.selectedValueIds.some((id) =>
                        param.valueIds.includes(id)
                      )
                  );
                }
              }
              return false;
            });
            return { ...section, isHidden: !hasHidden };
          }
        });
        setProductTemplate(updatedSections);
      };
      updateSections();
    }
  }, [productTemplate, subProducts]);

  useEffect(() => {
    if (subProducts?.length > 0) {
      let allParameters = subProducts.flatMap((x) => x.parameters);
      let parameterType = allParameters.find((p) => p.parameterCode == "types");
      if (!parameterType && quantityTypes.length > 0) {
        setQuantityTypes([]);
      } else if (
        parameterType &&
        Number(parameterType?.values[0] < 1 && quantityTypes.length > 0)
      ) {
        setQuantityTypes([]);
      }
    }
  }, [subProducts]);

  const [firstEnter, setFirstEnter] = useState(false);
  useEffect(() => {
    if (
      !firstEnter &&
      router?.query?.isAnalysis &&
      productTemplate?.sections?.length > 0
    ) {
      const visibleSections = productTemplate?.sections?.filter(
        (section) => !section.isHidden
      );
      const lastIndex = visibleSections.length;
      handleTabClick(lastIndex);
      setFirstEnter(true);
    }
  }, [router, productTemplate]);

  const validateWorkflows = (data) => {
    const errors = [];
    if (!data.actions || data.actions.length === 0) {
      errors.push(t("validateWorkflows.noAction"));
      return { isValid: false, errors };
    }
    for (let i = 0; i < data.actions.length; i++) {
      const action = data.actions[i];

      if (action.isNeedEmployee && !action.employeeId) {
        errors.push(
          `${action.actionName}: ${t("validateWorkflows.noEmployee")}`
        );
      }
      if (
        action.isNeedMachine &&
        !action.mongoDBMachineId &&
        action.source === 0
      ) {
        errors.push(
          `${action.actionName}: ${t("validateWorkflows.noMavhine")}`
        );
      }
      // if (
      //   action.isNeedMaterial &&
      //   (!action.materials || Object.keys(action.materials).length === 0)
      // ) {
      //   errors.push(
      //     `${action.actionName}: ${t("validateWorkflows.noMaterilas")}`
      //   );
      // }
      if ((action.source === 1 || action.source === 2) && !action.supplierId) {
        errors.push(
          `${action.actionName}: ${t("validateWorkflows.noSuppliers")}`
        );
      }
    }
    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  const getAllFoldOptionsApi = () => {
    const callBack = (res) => {
      if (res.success) {
        setFoldOptions(res?.data);
      }
    };
    getAllFoldOptions(callApi, callBack).then();
  };

  useEffect(() => {
    getAllFoldOptionsApi();
  }, []);
  const getJobStatus = (mission) => {
    if (mission?.isReady) {
      return t("boardMissions.done"); // For completed missions
    }

    if (mission?.boardMissionStatus && mission?.station) {
      const machineName = mission.station.machineName; // Get machine name
      const employeeName = mission.station.employeeName; // Get employee name

      // Initialize the job status string
      let statusString = `${mission.boardMissionStatus.name} | ${mission.station.actionName}`;

      // Check if both names exist and append them
      if (machineName && employeeName) {
        statusString += ` | ${employeeName} | ${machineName}`;
      } else if (employeeName) {
        statusString += ` | ${employeeName}`;
      } else if (machineName) {
        statusString += ` | ${machineName}`;
      }

      return statusString; // Return the formatted job status
    }

    return ""; // Return empty if no valid status or station
  };
  const formatMissions = (missions) => {
    return missions
      .map((mission) => {
        const jobNumber = mission.number;
        const productType = mission.productType;
        const currentStation = getJobStatus(mission); // Use getJobStatus here

        return `${jobNumber}/${productType}. ${currentStation}`;
      })
      .join(",\n"); // Joins with newline characters
  };
  return {
    t,
    router,
    handleTabClick,
    handleNextClick,
    handlePreviousClick,
    onCloseGalleryModal,
    onCloseMakeShape,
    handleChange,
    _renderParameterType,
    _getParameter,
    navigateForRouter,
    updateQuoteItem,
    setUrgentOrder,
    setPrintingNotes,
    setPriceRecovery,
    onCloseMultiParameterModal,
    duplicateSection,
    removeSection,
    duplicateParameters,
    setProductTemplate,
    multiParameterModal,
    calculationServerErrorState,
    settingParameters,
    setSettingParameters,
    priceRecovery,
    printingNotes,
    urgentOrder,
    makeShapeOpen,
    GalleryModalOpen,
    activeIndex,
    productTemplate,
    tabs,
    activeTab,
    PricingTab,
    expanded,
    errorMsg,
    relatedParameters,
    jobActions,
    workFlows,
    underParameterIds,
    sampleType,
    setSampleType,
    getOutSourcingSuppliers,
    onChangeSubProductsForPrice,
    includeVAT,
    setIncludeVAT,
    isChargeForNewDie,
    setIsChargeForNewDie,
    straightKnife,
    isChangePriceForEdit,
    onClickCloseChangePriceModal,
    setIsChangePriceForEdit,
    isLoadging,
    validateWorkflows,
    saveCurrentStation,
    onClickCloseCurrentStationModal,
    onClickOpenCurrentStationModal,
    missionsListForEdit,
    formatMissions,
    isFlowChanged,
    getSelectedWorkFlow,
    selectedWorkFlowCalculatedIsLoaded,
    setSelectedWorkFlowCalculatedIsLoaded
  };
};

export { useDigitalOffsetPrice };
